import styled from 'styled-components'
import { Button } from '@farewill/ui'
import { FONT } from '@farewill/ui/tokens'
import { useDispatch } from 'react-redux'

import Table from 'components/table'
import { showModal } from 'state/actions'

import { RemoveAccountModal } from './remove-account-modal'

interface RowProps {
  id: string
  email: string
}

const StyledRemoveButton = styled(Button)`
  font-weight: ${FONT.WEIGHT.MEDIUM};
  text-decoration: underline;
`

const Row = ({ id, email }: RowProps): React.ReactElement => {
  const dispatch = useDispatch()

  const onRemoveClick = () => {
    dispatch(
      showModal({
        component: RemoveAccountModal,
        headingText: 'Confirm you want to remove a user',
        config: { id, email },
      })
    )
  }

  return (
    <Table.Row gridTemplateCols="6fr 1fr">
      <Table.Col>{email}</Table.Col>
      <Table.Col>
        <StyledRemoveButton type="button" onClick={onRemoveClick}>
          Remove
        </StyledRemoveButton>
      </Table.Col>
    </Table.Row>
  )
}
export default Row
