import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import styled from 'styled-components'
import { H, P, Grid, Wrapper, Button, ChevronRightIcon } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

import CopyToClipboard from 'components/copy-to-clipboard'
import StyledLoader from 'components/loader'
import MessageBox from 'components/message-box'
import { Uppercase } from 'components/styled/uppercase'
import { PartnerSummary } from 'lib/models/partner'
import AccountsTable from 'routes/partners/components/accounts-table'
import { AddAccountModal } from 'routes/partners/components/accounts-table/add-account-modal'
import ProductPills from 'routes/partners/components/product-pills'
import PartnerSummaryTable from 'routes/partners/components/summary-table'
import { notionSpotlightUrl } from 'routes/partners/constants'
import { Auth0Metadata } from 'routes/partners/interfaces'
import { showModal } from 'state/actions'

const StyledCopyToClipboard = styled(CopyToClipboard)`
  position: absolute;
  right: 0;
  top: 0;
`
const StyledWrapper = styled(Wrapper)`
  background-color: ${COLOR.BACKGROUND.SMOKE};
`

const StyledAccountsHeader = styled(H)`
  border-top: 1px solid ${COLOR.GREY.LIGHT};
`

const StyledAddUserButton = styled(Button.Underline)`
  padding: ${GTR.M} 0;
`
const StyledChevronIcon = styled(ChevronRightIcon)`
  ${({ $rotate }) => $rotate && `transform: rotate(90deg);`}
`

const StyledP = styled(P)`
  cursor: pointer;
  padding-bottom: ${GTR.M};
  ${({ $expanded }) => $expanded && `padding-bottom: 0;`}
`

const ClipboardButton = ({
  onClick,
}: {
  onClick: () => void
}): React.ReactElement => (
  <Button.Plain
    aria-label="Copy to clipboard"
    onClick={() => onClick()}
    type="button"
    style={{ position: 'absolute', right: 0 }}
  >
    <span role="img" aria-hidden>
      📋
    </span>
  </Button.Plain>
)

interface OverviewTypes {
  partner?: PartnerSummary
  isLoading: boolean
}

const Overview = ({
  partner,
  isLoading,
}: OverviewTypes): React.ReactElement => {
  const [isExpanded, setIsExpanded] = useState(false)
  const dispatch = useDispatch()
  if (isLoading) return <StyledLoader />
  if (!partner) return <MessageBox error>Something has gone wrong.</MessageBox>
  const types = []
  if (partner.charity) types.push('Charity')
  if (partner.organisation) types.push('Organisation')

  const auth0Metadata = {
    charityName: partner.utmSource,
    partnerId: partner.id,
    canDownloadPartnerDataSharingReports: true,
  } as Auth0Metadata

  const auth0MetadataString = JSON.stringify(auth0Metadata)

  const onAddClick = () => {
    dispatch(
      showModal({
        component: AddAccountModal,
        headingText: 'Add a new user',
        config: { partnerId: partner.id },
      })
    )
  }

  return (
    <Wrapper padding={[GTR.L, 0, 0]}>
      <Grid>
        <Grid.Item spanFromL={7}>
          <Grid.Item>
            <Uppercase>{types.join(' & ')}</Uppercase>
          </Grid.Item>
          <H size="XL" decorative>
            <Grid.Item>{`${
              partner.charity?.legalName || partner.organisation?.name
            }`}</Grid.Item>
          </H>
          <H size="S">
            <Grid.Item>Partner details</Grid.Item>
          </H>
        </Grid.Item>
        {!!partner.products.length && (
          <ProductPills products={partner.products} />
        )}
        <Grid.Item spanFromL={7}>
          <PartnerSummaryTable values={partner} />
        </Grid.Item>

        <Grid.Item>
          <Button.Primary tag={Link} to={`/partners/${partner.id}/update`}>
            Edit partner details
          </Button.Primary>
        </Grid.Item>

        {partner.dataSharingReportType && (
          <Grid.Item spanFromL={7}>
            <StyledAccountsHeader size="S" padding={[GTR.XL, 0, 0]}>
              LIDS reports are enabled for {partner.utmSource}
            </StyledAccountsHeader>
            <StyledP
              onClick={() => setIsExpanded(!isExpanded)}
              $expanded={isExpanded}
            >
              <StyledChevronIcon size="S" $rotate={isExpanded} /> How to see
              this parther's LIDS reports
            </StyledP>

            {isExpanded && (
              <StyledWrapper margin={['L', 'auto']} padding="M">
                <P>
                  If you want to access this partner's LIDS reports on
                  Spotlight, copy the below information and follow the
                  instructions{' '}
                  <a href={notionSpotlightUrl} target="_blank" rel="noreferrer">
                    here
                  </a>
                  .
                </P>
                <Wrapper
                  style={{
                    position: 'relative',
                    backgroundColor: COLOR.WHITE,
                    borderRadius: GTR.XS,
                  }}
                >
                  <StyledCopyToClipboard
                    CustomButton={ClipboardButton}
                    hiddenChildren={JSON.stringify(
                      JSON.parse(auth0MetadataString),
                      null,
                      2
                    )}
                    propertyToCopy="textContent"
                  />
                  <P
                    size="S"
                    style={{
                      padding: GTR.M,
                      borderRadius: GTR.XXS,
                      fontFamily: 'monospace',
                      whiteSpace: 'pre-wrap',
                      lineHeight: '20px',
                    }}
                  >
                    {JSON.stringify(JSON.parse(auth0MetadataString), null, 2)}
                  </P>
                </Wrapper>
              </StyledWrapper>
            )}
          </Grid.Item>
        )}

        <Grid.Item spanFromL={7}>
          <StyledAccountsHeader size="S" padding={[GTR.XL, 0, 0]}>
            <Grid.Item>Spotlight users</Grid.Item>
          </StyledAccountsHeader>
          <Grid.Item spanFromL={7}>
            <AccountsTable accounts={partner.accounts} />
          </Grid.Item>
          <Grid.Item>
            <StyledAddUserButton onClick={onAddClick}>
              Add a new user
            </StyledAddUserButton>
          </Grid.Item>
        </Grid.Item>
      </Grid>
    </Wrapper>
  )
}
export default Overview
