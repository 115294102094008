import { Formik } from 'formik'
import { useEffect } from 'react'
import { Divider, Wrapper } from '@farewill/ui'

import ENV from 'config/environment'
import useApi from 'lib/effects/api'
import { FuneralPlanLeadAttributes } from 'lib/models/lead'
import { QuoteResponseDataFuneralPlan } from 'lib/types'
import { formatValuesForFormik } from 'utils/helpers'

import { useLeadContext } from '../context'
import QuoteForm from '../quote-form'

import Brochure from './brochure'
import ContactDetails from './contact-details'
import CouplePlan from './couple-plan'
import CoveredIndividual from './covered-individual'
import NominatedRepresentative from './nominated-representative'
import PaymentQuestions from './payment-questions'
import QualifyingQuestions from './qualifying-questions'
import VulnerabilityCheck from './vulnerability-check'
import Vulnerabilities from './vulnerabilities'
import RaisingMoneyForCharity from './raising-money-for-charity'

type Props = {
  disabled: boolean
}

const FuneralPlanForm = ({ disabled }: Props): React.ReactElement => {
  const { onSubmit, lead } = useLeadContext()
  const [{ data: quote }, makeRequest] = useApi<QuoteResponseDataFuneralPlan>()

  useEffect(() => {
    if (
      lead?.id &&
      (lead?.attributes as FuneralPlanLeadAttributes)?.quoteAttributes
        ?.paymentType
    ) {
      makeRequest({
        url: '/api/quotes',
        method: 'POST',
        data: {
          data: {
            type: 'quotes',
            attributes: {
              product: 'funeral_plan',
              leadId: lead.id,
            },
          },
        },
      })
    }
  }, [lead, makeRequest])

  return (
    <Formik
      initialValues={formatValuesForFormik(lead.attributes)}
      onSubmit={onSubmit}
    >
      <QuoteForm disabled={disabled}>
        <ContactDetails />
        <Divider margin={['L', 0]} />
        <Brochure />
        <Divider margin={['L', 0]} />
        <Vulnerabilities />
        <Divider margin={['L', 0]} />
        <CouplePlan />
        <Divider margin={['L', 0]} />
        <CoveredIndividual />
        <Divider margin={['L', 0]} />
        <QualifyingQuestions />
        <Divider margin={['L', 0]} />
        <NominatedRepresentative />
        <Divider margin={['L', 0]} />
        {ENV.FF_FUNERAL_PLANS_PARTNERSHIPS_ENABLED && (
          <>
            <RaisingMoneyForCharity />
            <Divider margin={['L', 0]} />
          </>
        )}
        <Wrapper margin={['L', 0]}>
          <VulnerabilityCheck />
        </Wrapper>
        <Divider margin={['L', 0]} />
        <PaymentQuestions
          quote={quote?.attributes}
          reference={lead.attributes.funeralPlanReference as string}
        />
      </QuoteForm>
    </Formik>
  )
}

export default FuneralPlanForm
