import { PropsWithChildren, useEffect, useRef } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector, useDispatch } from 'react-redux'
import { Prompt } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Formik, Form, useFormikContext, FormikHelpers } from 'formik'
import styled, { css } from 'styled-components'
import { Button, Grid, Wrapper } from '@farewill/ui'
import { screenMax, screenMin } from '@farewill/ui/helpers/responsive'
import { COLOR, GTR } from '@farewill/ui/tokens'

import { Uppercase } from 'components/styled/uppercase'
import Tabs from 'components/tabs'
import { Input, RadioGroup, SelectInput } from 'components/form/index'
import ENV from 'config/environment'
import { getUserIdFromToken } from 'lib/authentication/token'
import useApi from 'lib/effects/api'
import useSticky from 'lib/effects/sticky'
import { LEAD_EVENT_FORM_SCHEMA } from 'lib/formik/schemata'
import { Constants } from 'lib/models/constants'
import { FuneralLeadAttributes, Lead } from 'lib/models/lead'
import {
  createLeadEvent,
  fetchLeadEvents,
  addMessage,
  showModal,
} from 'state/actions'
import store from 'state/create-store'
import {
  FAREWILL_PRODUCTS,
  FUNERAL_BLOCKED_REASONS_OPTIONS,
  FUNERAL_LEAD_EVENT_OUTCOME_OPTIONS,
  LEAD_CONVERSION_SCORE_OPTIONS,
  LEAD_EVENT_DISPOSITION_OPTIONS,
  LEAD_EVENT_OUTCOME_OPTIONS,
  LEAD_EVENT_TYPE_OPTIONS,
  LEAD_EVENT_TYPES,
  LEAD_STATUS,
  LPA_UNSUITABLE_REASONS_OPTIONS,
  PROBATE_LEAD_SCORE_OPTIONS,
  PROBATE_CASE_SCORE_OPTIONS,
  FUNERAL_LEAD_STATUS,
} from 'utils/enums'
import { formatDateTimeToUTC } from 'utils/helpers'

import CallbackInfoBox from './callback-info-box'
import { DEFINED_TIMESLOTS } from './constants'
import { useLeadContext } from './context'
import ConvertedMessage from './converted-message'
import { PAYMENT_METHODS } from './helpers'
import LeadEventModal from './lead-event-modal'
import OwnerAssignment from './owner-assignment'
import ScheduledNextCallAt from './scheduled-next-call-at'
import { CustomLeadEvent } from './types'
import Note from './lead-event-form-note'

const BLOCKED_REASONS = {
  funeral: FUNERAL_BLOCKED_REASONS_OPTIONS,
}

const StyledFormWrapper = styled(
  ({ sticky, ...rest }: PropsWithChildren<{ sticky: boolean }>) => (
    <Wrapper {...rest} />
  )
)`
  background-color: ${COLOR.WHITE};
  padding: ${GTR.M} ${GTR.S};
  max-width: 384px;
  width: 100%;

  ${({ sticky }) =>
    sticky &&
    css`
      ${screenMin.l`
    position: fixed;
    top: ${GTR.L};
    max-height: calc(100vh - ${parseInt(GTR.L) * 2}px);
    overflow: auto;
    `}
    `}
`

const StyledGrid = styled(Grid)`
  ${screenMax.m`
      column-gap: 0;
    `}
`

const StyledOuterWrapper = styled(Wrapper)`
  height: 100%;
  box-shadow: -4px -2px 4px -2px rgba(0, 0, 0, 0.1);
`

const getIsBlockedOrOpenOrQualified = (status: string) => {
  return [
    FUNERAL_LEAD_STATUS.BLOCKED,
    LEAD_STATUS.OPEN,
    LEAD_STATUS.QUALIFIED,
  ].includes(status)
}

const getScheduledNextCallAt = ({
  values,
  lead,
}: {
  values: CustomLeadEvent
  lead: Lead
}) => {
  const { cancelScheduledNextCall, leadChanges } = values
  if (!getIsBlockedOrOpenOrQualified(leadChanges.status))
    return {
      scheduledNextCallAt: null,
      scheduledNextCallAtTimeSet: false,
    }

  const previousScheduledNextCallAt = lead.attributes
    .scheduledNextCallAt as unknown as Date

  if (previousScheduledNextCallAt && !cancelScheduledNextCall) {
    return {
      scheduledNextCallAt: formatDateTimeToUTC(previousScheduledNextCallAt),
      scheduledNextCallAtTimeSet: lead.attributes.scheduledNextCallAtTimeSet,
    }
  }

  if (!leadChanges.scheduledNextCallDate) {
    return {
      scheduledNextCallAt: null,
      scheduledNextCallAtTimeSet: false,
    }
  }

  const scheduledTime =
    leadChanges.scheduledNextCallTimeslot || leadChanges.scheduledNextCallTime

  if (scheduledTime) {
    const definedTimestamp = DEFINED_TIMESLOTS.find(
      (definedTimestamp) => definedTimestamp.value === scheduledTime
    )
    if (definedTimestamp) {
      return {
        scheduledNextCallAt: formatDateTimeToUTC(
          `${leadChanges.scheduledNextCallDate}T${definedTimestamp?.value}Z` as unknown as Date
        ),
        scheduledNextCallAtTimeSet: true,
      }
    }
    const scheduledNextCallTime = scheduledTime?.split('T')[1]
    return {
      scheduledNextCallAt: formatDateTimeToUTC(
        `${leadChanges.scheduledNextCallDate}T${scheduledNextCallTime}` as unknown as Date
      ),
      scheduledNextCallAtTimeSet: true,
    }
  }
  // if no time was set, we will store time in db as 23:59:59
  return {
    scheduledNextCallAt: formatDateTimeToUTC(
      `${leadChanges.scheduledNextCallDate}T23:59:59` as unknown as Date
    ),
    scheduledNextCallAtTimeSet: false,
  }
}

const hasRequiredPaymentReferences = (
  leadQuoteAttributes: FuneralLeadAttributes['quoteAttributes']
) => {
  if (leadQuoteAttributes.paymentMethod === PAYMENT_METHODS.invoiceAndCard)
    return !!(
      leadQuoteAttributes?.paymentReference &&
      leadQuoteAttributes?.invoice?.externalReferenceId
    )

  return !!(
    leadQuoteAttributes?.paymentReference ||
    leadQuoteAttributes?.invoice?.externalReferenceId
  )
}

const checkIsConnectedCall = (
  type: (typeof LEAD_EVENT_TYPES)[keyof typeof LEAD_EVENT_TYPES],
  disposition: string
) => type === LEAD_EVENT_TYPES.CALL_MADE && disposition === 'connected'

const getLeadEventOutcomeOptions = (lead: Lead) => {
  if (lead.attributes.product === FAREWILL_PRODUCTS.FUNERAL) {
    return FUNERAL_LEAD_EVENT_OUTCOME_OPTIONS
  }
  return LEAD_EVENT_OUTCOME_OPTIONS
}

const LeadEventFields = ({
  lead,
  loggedInAdminUserId,
}: {
  lead: Lead
  loggedInAdminUserId: number
}) => {
  const { isSubmitting, dirty, values, initialValues, setFieldValue } =
    useFormikContext<CustomLeadEvent>()
  const cache = useQueryClient()
  const constants = cache.getQueryData('constants') as Constants
  const leadUnsuitableReasonsOptions =
    constants?.attributes?.leadUnsuitableReasonsOptions
  const { leadChanges, type, metadata } = values
  const { status } = leadChanges
  const { disposition } = metadata
  const isBlocked = status === 'blocked'
  const isConverted = status === 'converted'
  const isLost = status === 'lost'
  const isCall = type === LEAD_EVENT_TYPES.CALL_MADE
  const activityType = isCall ? 'call' : 'note'
  const isProbate = lead.attributes.product === FAREWILL_PRODUCTS.PROBATE
  const isWill = lead.attributes.product === FAREWILL_PRODUCTS.WILL

  const UNSUITABLE_REASONS = {
    funeral_plan: leadUnsuitableReasonsOptions?.funeral_plan ?? [],
    funeral: leadUnsuitableReasonsOptions?.funeral ?? [],
    lpa: LPA_UNSUITABLE_REASONS_OPTIONS,
    probate: leadUnsuitableReasonsOptions?.probate ?? [],
    will: leadUnsuitableReasonsOptions?.will ?? [],
  }

  useEffect(() => {
    const isConnectedCall = checkIsConnectedCall(type, disposition)

    if (!isConnectedCall) {
      setFieldValue(
        'leadChanges.conversionScore',
        initialValues.leadChanges.conversionScore
      )
    }
  }, [type, disposition, initialValues, setFieldValue])

  useEffect(() => {
    if (!isCall) {
      setFieldValue(
        'leadChanges.ownedByAdminUserId',
        lead.attributes.ownedByAdminUserId
      )
    } else {
      setFieldValue(
        'leadChanges.ownedByAdminUserId',
        lead.attributes.ownedByAdminUserId || loggedInAdminUserId
      )
    }
  }, [
    isCall,
    initialValues,
    setFieldValue,
    lead.attributes.ownedByAdminUserId,
    loggedInAdminUserId,
  ])

  return (
    <Form>
      <Prompt
        when={dirty}
        message="Are you sure you want to leave this page? The activity form has unsaved changes."
      />
      <StyledGrid>
        <Grid.Item>
          <Tabs name="type" options={LEAD_EVENT_TYPE_OPTIONS} />
        </Grid.Item>
        <Grid.Item>
          {ENV.FF_AI_NOTES_ENABLED ? (
            <Note metadata={metadata} />
          ) : (
            <Input
              name="metadata.notes"
              component="textarea"
              label="Notes"
              rows={6}
            />
          )}
        </Grid.Item>
        {isCall && (
          <Grid.Item>
            <RadioGroup
              small
              name="metadata.disposition"
              options={LEAD_EVENT_DISPOSITION_OPTIONS}
              label="Outcome"
              allowDeselect={false}
            />
          </Grid.Item>
        )}
        <Grid.Item>
          <RadioGroup
            small
            name="leadChanges.conversionScore"
            options={
              isProbate
                ? PROBATE_LEAD_SCORE_OPTIONS
                : LEAD_CONVERSION_SCORE_OPTIONS
            }
            label="Lead score"
            enableTooltip
            padding={`${GTR.XS} ${GTR.M}`}
            allowDeselect={false}
            // for probate leads, we do not want users to be able to manually set the lead score
            disabled={!checkIsConnectedCall(type, disposition) || isProbate}
          />
        </Grid.Item>
        <Grid.Item>
          <RadioGroup
            small
            name="leadChanges.status"
            options={getLeadEventOutcomeOptions(lead)}
            label="Change lead status (optional)"
            allowDeselect={false}
            highlightInitialValue
          />
        </Grid.Item>
        {isProbate && (
          <Grid.Item>
            <RadioGroup
              small
              name="leadChanges.caseScore"
              options={PROBATE_CASE_SCORE_OPTIONS}
              label="Case score"
              enableTooltip
              padding={`${GTR.XS} ${GTR.M}`}
              allowDeselect={false}
              disabled={!checkIsConnectedCall(type, disposition)}
            />
          </Grid.Item>
        )}

        <Grid.Item>
          <OwnerAssignment
            lead={lead}
            loggedInAdminUserId={loggedInAdminUserId}
          />
        </Grid.Item>

        {getIsBlockedOrOpenOrQualified(status) && (
          <ScheduledNextCallAt lead={lead} />
        )}
        {isConverted && <ConvertedMessage lead={lead} />}
        {isLost && (
          <Grid.Item>
            <SelectInput
              label="Unsuitable reason"
              name="leadChanges.unsuitableReason"
              options={UNSUITABLE_REASONS[lead.attributes.product]}
              onChange={(data) => {
                if (
                  data &&
                  'value' in data &&
                  (data?.value as string).slice(0, 13) === 'uncontactable' &&
                  isWill &&
                  ENV.FF_WILLS_BOOKED_CALL_EMAILS_ENABLED
                ) {
                  store.dispatch(
                    showModal({
                      component: LeadEventModal,
                      headingText: 'You are about to email this customer',
                      config: {
                        confirmCallback: () => {
                          setFieldValue(
                            'leadChanges.unsuitableReason',
                            data?.value
                          )
                        },
                      },
                    })
                  )
                } else {
                  data &&
                    'value' in data &&
                    setFieldValue('leadChanges.unsuitableReason', data?.value)
                }
              }}
            />
          </Grid.Item>
        )}
        {isBlocked && (
          <Grid.Item>
            <SelectInput
              label="Blocked reason"
              name="leadChanges.blockedReason"
              options={
                BLOCKED_REASONS[
                  lead.attributes.product as keyof typeof BLOCKED_REASONS
                ]
              }
            />
          </Grid.Item>
        )}
        <Grid.Item>
          <Button.Primary type="submit" disabled={isSubmitting || !dirty}>
            Add {activityType}
          </Button.Primary>
        </Grid.Item>
      </StyledGrid>
    </Form>
  )
}

const LeadEventForm = () => {
  const [, makeRequest] = useApi()
  const sidebarEl = useRef(null)
  const [sticky] = useSticky(sidebarEl, 'lead')
  const { fetchLead, lead } = useLeadContext()
  const dispatch = useDispatch()

  const token = useSelector((state: { token: string }) => state.token)
  const loggedInAdminUserId = getUserIdFromToken(token)

  const initialValues = {
    type: LEAD_EVENT_TYPES.CALL_MADE,
    metadata: {
      notes: '',
      disposition: '',
    },
    leadChanges: {
      status: lead.attributes.status,
      scheduledNextCallAtTimeSet: false,
      scheduledNextCallDate: '',
      scheduledNextCallTime: '',
      unsuitableReason: lead.attributes.unsuitableReason || '',
      blockedReason: lead.attributes.blockedReason || '',
      conversionScore: lead.attributes.conversionScore
        ? String(lead.attributes.conversionScore)
        : '',
      caseScore: lead.attributes.caseScore
        ? String(lead.attributes.caseScore)
        : '',
      ownedByAdminUserId:
        lead.attributes.ownedByAdminUserId || loggedInAdminUserId,
    },
    cancelScheduledNextCall: false,
    changeAssignedOwner: false,
  } as CustomLeadEvent

  const handleSubmit = async (
    values: CustomLeadEvent,
    actions: FormikHelpers<CustomLeadEvent>
  ) => {
    const {
      status,
      unsuitableReason,
      blockedReason,
      conversionScore,
      caseScore,
      ownedByAdminUserId,
    } = values.leadChanges
    const { disposition } = values.metadata
    const isLost = status === LEAD_STATUS.LOST
    const isBlocked = status === FUNERAL_LEAD_STATUS.BLOCKED
    const isConverted = status === LEAD_STATUS.CONVERTED

    const { product } = lead.attributes
    if (disposition === 'connected' || disposition === 'no_answer') {
      try {
        await makeRequest({
          method: 'POST',
          url: '/api/customer-calls',
          data: {
            data: {
              type: 'customer_calls',
              attributes: {
                leadUuid: lead.attributes.uuid,
                status: disposition === 'connected' ? 'connected' : 'missed',
                type: 'sales',
              },
            },
          },
        })
      } catch (error) {
        actions.setSubmitting(false)
      }
    }

    /* Validation checks before a lead can be converted */
    if (isConverted) {
      /** Funeral leads require a payment before conversion unless the calculated
       * price is £0, such as when the lead is a Funeral Plan redemption with no
       * additional costs to pay. **/
      if (product === FAREWILL_PRODUCTS.FUNERAL) {
        const { calculatedPrice } = lead.attributes
        const isQuotedZero = calculatedPrice === 0

        const isPaid = hasRequiredPaymentReferences(
          lead.attributes.quoteAttributes
        )
        if (!isPaid && !isQuotedZero) {
          /* If the calculatedPrice is null, the quote form fields haven't been completed */
          const errorReason = calculatedPrice
            ? 'payment reference and/or invoice reference is provided'
            : 'quote questions have been completed'
          actions.setFieldError(
            'leadChanges.status',
            `Lead cannot be converted unless the ${errorReason}.`
          )
          return
        }
      }

      if (product === FAREWILL_PRODUCTS.PROBATE) {
        /* interestedInEstateAdmin must be true or false for conversion*/
        if (
          lead.attributes.quoteAttributes.interestedInEstateAdmin === undefined
        ) {
          actions.setFieldError(
            'leadChanges.status',
            'Lead cannot be converted unless we have chosen whether the caller prefers Essential or Complete Probate.'
          )
          return
        }

        if (!caseScore) {
          actions.setFieldError(
            'leadChanges.status',
            'Lead cannot be converted unless we have assigned a case score.'
          )
          return
        }
      }

      /**
       * Funeral plan lead requires either email address or isPostalService with address
       */
      if (product === FAREWILL_PRODUCTS.FUNERAL_PLAN) {
        const { contact, quoteAttributes } = lead.attributes

        if (
          !contact.email &&
          quoteAttributes.isPostalService &&
          !contact.addresses?.[0]
        ) {
          actions.setFieldError(
            'leadChanges.status',
            'Lead cannot be converted unless we have email address or customer requested postal service with address provided'
          )
          return
        }

        if (!contact.addresses?.[0]) {
          actions.setFieldError(
            'leadChanges.status',
            'Lead cannot be converted unless we have address of purchaser'
          )
          return
        }

        if (!contact.email && !quoteAttributes.isPostalService) {
          actions.setFieldError(
            'leadChanges.status',
            'Lead cannot be converted unless we have email of purchaser or customer requested postal service'
          )
          return
        }
      }
    }

    const postData = {
      leadId: lead.id,
      type: values.type,
      metadata: {
        disposition: values.metadata.disposition || null,
        notes: values.metadata.notes || null,
      },
      leadChanges: {
        status,
        ...getScheduledNextCallAt({ values, lead }),
        unsuitableReason: (isLost && unsuitableReason) || null,
        blockedReason: (isBlocked && blockedReason) || null,
        conversionScore: Number(conversionScore) || null,
        caseScore: Number(caseScore) || null,
        ownedByAdminUserId: ownedByAdminUserId || null,
      },
    }

    try {
      const leadEventResponseAction = await dispatch(createLeadEvent(postData))
      const leadEventResponse = leadEventResponseAction as unknown as {
        value: { data: { data: CustomLeadEvent; meta: { messages: string[] } } }
      }
      await fetchLead()
      await dispatch(fetchLeadEvents({ params: { leadId: lead.id } }))
      const messages = leadEventResponse.value.data.meta?.messages

      if (messages?.length > 0) {
        messages.forEach((message) => dispatch(addMessage(message)))
      }

      actions.resetForm()
      toast('Success! Details saved.', {
        toastId: 'lead-event-details-saved',
      })
    } catch {
      actions.setSubmitting(false)
    }
  }

  return (
    <StyledOuterWrapper padding={['M', 0, 0]}>
      <div ref={sidebarEl}>
        <StyledFormWrapper sticky={sticky}>
          {lead.attributes.product === FAREWILL_PRODUCTS.WILL &&
            ENV.FF_WILLS_BOOKED_CALL_EMAILS_ENABLED && <CallbackInfoBox />}
          <Wrapper margin={[0, 0, 'S']}>
            <Uppercase>Activity log</Uppercase>
          </Wrapper>
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={LEAD_EVENT_FORM_SCHEMA}
            enableReinitialize
          >
            <LeadEventFields
              lead={lead}
              loggedInAdminUserId={loggedInAdminUserId}
            />
          </Formik>
        </StyledFormWrapper>
      </div>
    </StyledOuterWrapper>
  )
}

export default LeadEventForm
