import { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Formik, FormikContextType, useFormikContext } from 'formik'
import { Button, DeleteIcon, H, P, PlusIcon, Wrapper } from '@farewill/ui'
import { BORDER, COLOR, FONT, GTR } from '@farewill/ui/tokens'
import get from 'lodash/get'
import set from 'lodash/set'

import useDebounce from 'lib/effects/debounce'
import useApi from 'lib/effects/api'
import { formatValuesForFormik } from 'utils/helpers'

import {
  FloatingWrapper,
  FloatingLabel,
  FloatingInput,
  FloatingHint,
} from 'components/styled/helpers/floating'
import { getFieldState } from 'components/form/helpers'
import Tooltip from 'components/tooltip'
import Label from 'components/styled/label'
import {
  HandleCharityChange,
  SingleCharityValue,
} from 'routes/lead/funeral-plan-form/raising-money-for-charity'

const StyledFloatingHint = styled(FloatingHint)`
  margin-top: ${GTR.XXS};
`

const StyledInputMethodButton = styled(Button)`
  text-decoration: underline;
  font-weight: ${FONT.WEIGHT.REGULAR};
  font-size: ${FONT.SIZE.XS};

  &:hover {
    text-decoration: underline;
  }
`

const StyledButton = styled(Button)`
  font-size: 0;
`

const StyledCharityCardWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
`

const ResultsListWrapper = styled.div`
  position: absolute;
  top: 58px;
  width: 100%;
  border: 1px solid ${COLOR.GREY.LIGHT};
  background-color: ${COLOR.WHITE};
  border-radius: ${BORDER.RADIUS.S};
  box-shadow: ${BORDER.SHADOW.M};
  z-index: 1;
`

const CustomResult = styled.ul`
  width: 100%;
  margin: 0;
  background-color: ${COLOR.BACKGROUND.FOG};
  border-top: 1px solid ${COLOR.GREY.LIGHT};

  li {
    padding-top: ${GTR.S};
    padding-bottom: ${GTR.S};
    display: flex;
    align-items: center;
    gap: ${GTR.XS};

    &:hover,
    &:focus {
      background-color: ${COLOR.BACKGROUND.SMOKE};
    }
  }
`

const ResultsList = styled.ul`
  width: 100%;

  padding: 0;
  max-height: 400px;
  overflow: auto;
  z-index: 1;
  margin: 0;

  &:before {
    content: '';
    display: block;
    height: 1px;
    background-color: ${COLOR.GREY.LIGHT};
    width: calc(100% - ${GTR.L});
    position: absolute;
    top: 0;
    left: ${GTR.S};
  }
`

const ResultLink = styled.li`
  padding: ${GTR.XS} ${GTR.S};
  width: 100%;
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: ${COLOR.BACKGROUND.FOG};
    cursor: pointer;
    text-decoration: none;
  }
`

const SearchLoadingMessage = styled.li`
  padding: ${GTR.XS} ${GTR.S};
  width: 100%;
  text-decoration: none;
`

const ResultDescription = styled.div`
  font-weight: ${FONT.WEIGHT.REGULAR};
  font-size: ${FONT.SIZE.XS};
`

const charityIsEmpty = (
  values:
    | {
        name: string
      }
    | undefined
) => {
  if (!values) return true
  const { name } = values
  return !name
}

type CharityInputContext = {
  charity: {
    search: string
    charityFields: SingleCharityValue
  }
}

const Hint = ({ onTrashClick }: { onTrashClick?: () => void }) => (
  <StyledFloatingHint>
    <StyledInputMethodButton type="button" onClick={onTrashClick}>
      Remove or change this charity
    </StyledInputMethodButton>
  </StyledFloatingHint>
)

const CharityCard = ({ onTrashClick }: { onTrashClick?: () => void }) => {
  const { values } = useFormikContext<CharityInputContext>()
  const { name, number } = values.charity.charityFields
  return (
    <>
      <StyledCharityCardWrapper
        data-testid="charity-card"
        padding={GTR.M}
        bordered
      >
        <Wrapper>
          <H tag="h3" color={COLOR.ACCENT.SECONDARY} margin={[0, 0, 'XXS']}>
            {name}
          </H>
          {number && number.length > 0 && (
            <P padding={0} size="S" color={COLOR.GREY.MEDIUM}>
              Charity number: {number}
            </P>
          )}
        </Wrapper>
        {onTrashClick && (
          <Wrapper>
            <Tooltip content="Remove selected charity">
              <StyledButton
                type="button"
                onClick={onTrashClick}
                data-testid="delete-card-button"
              >
                <DeleteIcon size="S" />
                Delete
              </StyledButton>
            </Tooltip>
          </Wrapper>
        )}
      </StyledCharityCardWrapper>
      <Hint onTrashClick={onTrashClick} />
    </>
  )
}

const CharityForm = ({
  onCharityChanged,
  charityValues,
  label,
  name,

  disabled,
}: {
  onCharityChanged?: HandleCharityChange
  charityValues: SingleCharityValue
  label: string
  name: string

  disabled?: boolean
}) => {
  const formik = useFormikContext<CharityInputContext>()
  const { handleChange, setFieldValue, values } = formik

  const searchEl = useRef<HTMLDivElement>(null)

  const [isSearching, setIsSearching] = useState(charityIsEmpty(charityValues))
  const [focused, setFocused] = useState(false)
  const [searchQuery, setSearchQuery] = useState<{
    query: string
  }>({
    query: '',
  })
  const debouncedSearchQuery = useDebounce(searchQuery, 200)

  const [{ data: searchResponse, isLoading }, makeRequest] = useApi<{
    attributes: { results: { name: string; number: string }[] }
  }>()

  useEffect(() => {
    setFieldValue(name, {
      search: '',
      charityFields: charityValues,
    })
    if (!charityIsEmpty(charityValues)) {
      setIsSearching(false)
    }
  }, [setFieldValue, name, charityValues])

  useEffect(() => {
    if (debouncedSearchQuery.query) {
      makeRequest({
        url: '/api/charity-lookups/search',
        method: 'POST',
        data: {
          data: {
            type: 'charity_lookups',
            attributes: debouncedSearchQuery,
          },
        },
      })
    }
  }, [debouncedSearchQuery, makeRequest])

  useEffect(() => {
    const handleCloseClick = (e: MouseEvent) => {
      if (
        focused &&
        searchEl?.current &&
        e.target instanceof Node &&
        !searchEl.current.contains(e.target)
      ) {
        setFocused(false)
      }
    }

    document.addEventListener('click', handleCloseClick)
    return () => document.removeEventListener('click', handleCloseClick)
  }, [focused])

  const onSearchResultClick = async ({
    result,
    setFieldValue,
  }: {
    result: {
      name: string
      number: string
    }
    setFieldValue: FormikContextType<unknown>['setFieldValue']
  }) => {
    setIsSearching(false)
    setFieldValue(name, {
      search: '',
      charityFields: {
        name: result.name,
        number: result.number,
      },
    })
    onCharityChanged &&
      onCharityChanged({
        name: result.name,
        number: result.number,
      })
  }

  const showResultsList = focused && get(values, name).search
  const hasCurrentSearchResults = !isLoading && searchResponse
  const isEnteringManually = !isSearching
  const { hasValue, hasHighlight } = getFieldState({
    formik,
    name: `${name}.search`,
  })

  const handleRemoveCharity = () => {
    setFieldValue(name, {
      search: '',
      charityFields: {
        name: '',
        number: '',
      },
    })
    setIsSearching(true)
    onCharityChanged &&
      onCharityChanged({
        name: '',
        number: '',
      })
  }

  return (
    <>
      {isSearching && (
        <div ref={searchEl}>
          <Label htmlFor={`${name}.search`} $error={false}>
            Charity name
          </Label>
          <FloatingWrapper highlight={hasHighlight}>
            <FloatingInput
              highlight={hasHighlight}
              id={`${name}.search`}
              name={`${name}.search`}
              onFocus={() => setFocused(true)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(e)
                setFocused(true)
                setSearchQuery({ query: e.target.value })
              }}
              disabled={disabled}
            />

            {showResultsList && (
              <ResultsListWrapper>
                <ResultsList data-testid="charity-results-list">
                  {isLoading && (
                    <SearchLoadingMessage>Loading...</SearchLoadingMessage>
                  )}
                  {hasCurrentSearchResults &&
                    searchResponse.attributes.results.map((result) => (
                      <ResultLink
                        key={result.number}
                        onClick={() => {
                          onSearchResultClick({ result, setFieldValue })
                        }}
                      >
                        <div>{result.name}</div>
                        <ResultDescription>
                          Charity number: {result.number}
                        </ResultDescription>
                      </ResultLink>
                    ))}
                </ResultsList>
                {searchQuery.query.length > 2 && (
                  <CustomResult>
                    <ResultLink>
                      <Button.Plain
                        data-testid="add-custom-charity-button"
                        padding={0}
                        margin={0}
                        flush
                        onClick={() => {
                          onSearchResultClick({
                            result: {
                              name: searchQuery.query,
                              number: '',
                            },
                            setFieldValue,
                          })
                        }}
                      >
                        <PlusIcon />{' '}
                        <span>
                          Add <strong>{searchQuery.query}</strong> as custom
                          charity
                        </span>
                      </Button.Plain>
                    </ResultLink>
                  </CustomResult>
                )}
              </ResultsListWrapper>
            )}

            <FloatingLabel htmlFor={`${name}.search`} full={hasValue}>
              {label}
            </FloatingLabel>
          </FloatingWrapper>
        </div>
      )}
      {isEnteringManually && <CharityCard onTrashClick={handleRemoveCharity} />}
    </>
  )
}

const CharityInput = ({
  charityValues = {
    name: '',
    number: '',
  },
  onCharityChanged,
  label,
  name,
  disabled,
}: {
  charityValues?: SingleCharityValue
  onCharityChanged?: HandleCharityChange
  label: string
  name: string
  disabled?: boolean
}) => {
  const { handleSubmit } = useFormikContext()
  const charityFields = formatValuesForFormik(charityValues)

  return (
    <Formik
      initialValues={set({}, name, { search: '', charityFields })}
      onSubmit={() => handleSubmit()}
    >
      <CharityForm
        charityValues={charityValues}
        onCharityChanged={onCharityChanged}
        label={label}
        name={name}
        disabled={disabled}
      />
    </Formik>
  )
}

export default CharityInput
