import { useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import { useField } from 'formik'
import { Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import uniqueId from 'lodash/uniqueId'

import 'react-widgets/dist/css/react-widgets.css'

import Label from 'components/styled/label'
import Hint from 'components/styled/input-hint'
import Error from 'components/form/error'

momentLocalizer()

const StyledDateTimePicker = styled(DateTimePicker)`
  &.rw-state-focus {
    .rw-widget-container {
      border-color: ${COLOR.GREY.MEDIUM};
      box-shadow: none;
    }
  }

  .rw-widget-picker {
    width: 100%;
    border: 2px solid ${COLOR.GREY.LIGHT};
    transition: border-color 0.2s ease-in-out;
  }

  .rw-widget-input {
    color: ${COLOR.BLACK};
    padding: ${GTR.S};
  }

  .rw-btn-select {
    color: ${COLOR.GREY.DARK};
    opacity: 1;
    transform: translateY(-3px);

    &:hover {
      background-color: ${COLOR.BACKGROUND.FOG};
    }
  }

  .rw-select-bordered {
    border-left: 2px solid ${COLOR.GREY.LIGHT};

    &:hover {
      background-color: transparent;
    }

    &:active {
      box-shadow: none;
      background-color: transparent;
    }
  }

  .rw-cell {
    &.rw-state-selected,
    &.rw-state-selected:hover {
      background-color: ${COLOR.ACCENT.SECONDARY};
      border-color: ${COLOR.ACCENT.SECONDARY};
    }
  }

  .rw-list-option {
    &.rw-state-focus,
    &.rw-state-focus:hover {
      border-color: ${COLOR.ACCENT.SECONDARY};
    }
  }
`

const convertToDateObject = (dateString: string, time: boolean): Date => {
  if (time) return new Date(dateString)

  const dateParts = dateString.split('-')
  return new Date(
    Number(dateParts[0]),
    Number(dateParts[1]) - 1,
    Number(dateParts[2])
  )
}

const convertToDateString = (value: Date, time: boolean) => {
  return time
    ? moment(value).utc().format()
    : moment(value).format('YYYY-MM-DD')
}

const getDisplayFormat = ({ date, time }: { date: boolean; time: boolean }) => {
  if (date && time) return 'D MMM YYYY, HH:mm'
  if (date) return 'D MMM YYYY'
  return 'HH:mm'
}

export type DatetimePickerProps = {
  className?: string
  disabled?: boolean
  name: string
  label?: string
  handleSave?: (params: { name: string; value: string }) => void
  hint?: string
  date?: boolean
  time?: boolean
  min?: string
  max?: string
  dropUp?: boolean
  currentDate?: Date
}

const DatetimePicker = ({
  className = '',
  disabled = false,
  name,
  label,
  handleSave,
  hint = '',
  date = true,
  time = true,
  min,
  max,
  dropUp,
  currentDate,
}: DatetimePickerProps): React.ReactElement => {
  const [field, meta, helpers] = useField(name)
  const value = field.value ? convertToDateObject(field.value, time) : undefined
  const displayFormat = getDisplayFormat({ date, time })
  const labelId = useRef(uniqueId(`${name}-`))
  const hasError = !!(meta.error && meta.touched)

  return (
    <Wrapper className={className}>
      {label && (
        <Label id={labelId.current} $error={hasError}>
          {label}
        </Label>
      )}
      <StyledDateTimePicker
        disabled={disabled}
        name={name}
        aria-labelledby={labelId.current}
        value={value}
        onChange={(value) => {
          const dateString = value ? convertToDateString(value, time) : null
          helpers.setValue(dateString)
        }}
        onBlur={(e) => {
          field.onBlur(e)
          handleSave && handleSave({ name, value: field.value })
        }}
        date={date}
        time={time}
        format={displayFormat}
        min={min ? convertToDateObject(min, time) : undefined}
        max={max ? convertToDateObject(max, time) : undefined}
        dropUp={dropUp}
        currentDate={currentDate}
      />
      {hint && <Hint>{hint}</Hint>}
      <Error name={name} />
    </Wrapper>
  )
}

// TODO: Remove when all parent components are using TypeScript
DatetimePicker.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  handleSave: PropTypes.func,
  hint: PropTypes.string,
  label: PropTypes.string,
  max: PropTypes.string,
  min: PropTypes.string,
  name: PropTypes.string.isRequired,
  date: PropTypes.bool,
  time: PropTypes.bool,
}

export default DatetimePicker
