import { useEffect } from 'react'
import { Button, CalendarIcon, Grid, P, Wrapper } from '@farewill/ui'
import { BORDER_RADIUS, COLOR, FONT, GTR } from '@farewill/ui/tokens'
import styled from 'styled-components'
import { connect, useFormikContext } from 'formik'
import moment from 'moment'

import DatetimePicker from 'components/form/datetime-picker'
import Checkbox from 'components/form/checkbox'
import RadioGroup from 'components/form/radio-group'
import { Lead } from 'lib/models/lead'
import { DEFINED_TIMESLOTS } from './constants'
import { getDefinedTimeslot, toLocalISOString } from './helpers'
import { CustomLeadEvent } from './types'

const StyledMessageWrapper = styled(Wrapper)`
  background: ${COLOR.ACCENT.PRIMARY_20};
  border: 2px solid ${COLOR.ACCENT.PRIMARY_120};
  border-radius: ${BORDER_RADIUS.S};
  padding: ${GTR.S};
`

const StyledInnerWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  column-gap: ${GTR.XS};
`

const StyledRescheduleButton = styled(Button)`
  text-decoration: underline;
  font-weight: ${FONT.WEIGHT.REGULAR};

  &:hover {
    text-decoration: underline;
  }
`

const StyledDatetimePicker = styled(DatetimePicker)`
  margin: ${GTR.M} 0 0 12px;
  padding-left: ${GTR.M};
  border-left: 4px solid ${COLOR.GREY.LIGHT};
`
/** we display the timeslot if the timestamp matches one of the defined
 * timeslots, or the specific time in the HH:mm format */
const getCallbackBookedAtText = (
  scheduledNextCallAt: string,
  scheduledNextCallAtTimeSet?: boolean
): string => {
  let when = ''
  if (scheduledNextCallAtTimeSet) {
    const timeslot = getDefinedTimeslot(scheduledNextCallAt)
    if (timeslot) {
      when = ` · ${timeslot.label}`
    } else {
      const scheduledNextCallAtFormatted = toLocalISOString(
        new Date(scheduledNextCallAt)
      )
      when = ` · ${scheduledNextCallAtFormatted.split('T')[1].slice(0, 5)}`
    }
  }

  return moment(scheduledNextCallAt).format('DD MMM YYYY') + when
}

const ScheduledNextCallAt = ({ lead }: { lead: Lead }) => {
  const { values, setFieldValue } = useFormikContext<CustomLeadEvent>()
  const { cancelScheduledNextCall, leadChanges } = values
  const { scheduledNextCallAt, scheduledNextCallAtTimeSet } = lead.attributes
  const inputVisible = cancelScheduledNextCall || !scheduledNextCallAt

  /** If a user ticks the box to set a specific time, we need to clear the
   * scheduledNextCallTimeSlot field.
   * If a user unticks the box, we need to clear scheduledNextCallTime field. */
  useEffect(() => {
    const isSpecificTimeCheckboxSelected =
      leadChanges.scheduledNextCallAtTimeSet === true
    const isSpecificTimeCheckBoxDeselected =
      leadChanges.scheduledNextCallAtTimeSet === false

    if (isSpecificTimeCheckboxSelected) {
      setFieldValue('leadChanges.scheduledNextCallTimeslot', '')
    }

    if (isSpecificTimeCheckBoxDeselected) {
      setFieldValue('leadChanges.scheduledNextCallTime', '')
    }
  }, [leadChanges.scheduledNextCallAtTimeSet, setFieldValue])

  return inputVisible ? (
    <>
      <Grid.Item>
        <DatetimePicker
          label="Next callback"
          name="leadChanges.scheduledNextCallDate"
          time={false}
          dropUp
        />
      </Grid.Item>
      {leadChanges.scheduledNextCallDate && (
        <>
          <Grid.Item>
            <RadioGroup
              small
              label="Call time (optional)"
              name="leadChanges.scheduledNextCallTimeslot"
              options={DEFINED_TIMESLOTS}
              disabled={leadChanges.scheduledNextCallAtTimeSet}
            />
          </Grid.Item>
          <Grid.Item data-testid="call-time-picker">
            <Checkbox
              label="Select a specific time"
              name="leadChanges.scheduledNextCallAtTimeSet"
            />
            {leadChanges.scheduledNextCallAtTimeSet && (
              <StyledDatetimePicker
                className="call-time-picker-input"
                name="leadChanges.scheduledNextCallTime"
                date={false}
                dropUp
                currentDate={new Date(leadChanges.scheduledNextCallDate)}
              />
            )}
          </Grid.Item>
        </>
      )}
    </>
  ) : (
    <Grid.Item>
      <P margin={[0, 0, 'XS']}>Next callback</P>
      <StyledMessageWrapper>
        <StyledInnerWrapper margin={[0, 0, 'XS']}>
          <CalendarIcon size="M" />
          <P margin={0}>
            {getCallbackBookedAtText(
              scheduledNextCallAt,
              scheduledNextCallAtTimeSet
            )}
          </P>
        </StyledInnerWrapper>
        <StyledRescheduleButton
          onClick={() => {
            setFieldValue('cancelScheduledNextCall', true)
            setFieldValue('leadChanges.scheduledNextCallAtTimeSet', false)
          }}
        >
          Remove / reschedule
        </StyledRescheduleButton>
      </StyledMessageWrapper>
    </Grid.Item>
  )
}

export default connect(ScheduledNextCallAt)
