import { useEffect } from 'react'
import styled from 'styled-components'
import { Grid, Wrapper, H } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { useRouteMatch } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { Redirect } from 'react-router-dom'

import { getFullNameWithPreferredName } from 'utils/helpers'

import { Uppercase } from 'components/styled/uppercase'
import NavigationTabs from 'components/navigation-tabs'
import SimpleList from 'components/styled/simple-list'
import TelephoneAndEmailContacts from 'components/phone-email-contacts'
import PrivateRoute from 'components/private-route'
import MemberBadge from 'components/member-badge'
import NotFound from 'routes/not-found'

import KeyInformation from './key-information'
import History from './history'
import Products from './products'
import Account from './account'

import { ContactContextProvider, useContactContext } from './context'

type ContactProps = {
  match: { path: string; params: { id: number } }
}

const StyledTitleWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  gap: ${GTR.XS};
`

const Contact = ({ match }: ContactProps) => {
  const { path, params } = match
  const contactId = params.id

  const { contact, fetchContact, isFetchingContact } = useContactContext()
  const noContact = isEmpty(contact)

  useEffect(() => {
    fetchContact()
  }, [contactId, fetchContact])

  const isMatch = useRouteMatch({ path: '/contacts/:id', exact: true })
  if (isMatch) return <Redirect to={`/contacts/${contactId}/products`} />

  if (isFetchingContact && noContact) return <Uppercase>Loading...</Uppercase>

  if (noContact) return <NotFound />

  const { attributes } = contact
  const hasRelatedAccount = !!attributes.accountUuid
  const NAV_LINKS = [
    { label: 'Products', url: `/contacts/${contactId}/products` },
    { label: 'History', url: `/contacts/${contactId}/history` },
    { label: 'Key information', url: `/contacts/${contactId}/edit` },
  ]

  if (hasRelatedAccount) {
    NAV_LINKS.push({
      label: 'Account details',
      url: `/contacts/${contactId}/account`,
    })
  }
  return (
    <>
      <Grid separator padding={[GTR.L, 0, 0]}>
        <Grid.Item spanFromM={8}>
          <Uppercase>Contact</Uppercase>
          <StyledTitleWrapper margin={[0, 0, 'XS']}>
            <H size="XL" decorative margin={0}>
              {getFullNameWithPreferredName(attributes) || 'New contact'}
            </H>
            {attributes?.membership?.number && <MemberBadge />}
          </StyledTitleWrapper>
          <TelephoneAndEmailContacts
            contact={contact}
            contactId={contactId}
            withLink
          />
        </Grid.Item>
        {attributes.externalZendeskId && (
          <Grid.Item spanFromM={4}>
            <Uppercase>See also</Uppercase>
            <SimpleList small>
              <li>
                <a
                  href={`https://farewill.zendesk.com/agent/users/${attributes.externalZendeskId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View on Zendesk
                </a>
              </li>
            </SimpleList>
          </Grid.Item>
        )}
      </Grid>
      <Wrapper separator>
        <NavigationTabs links={NAV_LINKS} />
      </Wrapper>
      <Wrapper separator>
        <PrivateRoute path={`${path}/edit`} component={KeyInformation} />
        <PrivateRoute
          path={`${path}/history`}
          component={History}
          contactId={contactId}
        />
        <PrivateRoute exact path={`${path}/products`} component={Products} />
        {hasRelatedAccount && (
          <PrivateRoute exact path={`${path}/account`} component={Account} />
        )}
      </Wrapper>
    </>
  )
}

const ContactWithContext = (props: ContactProps) => (
  <ContactContextProvider contactId={props.match.params.id}>
    <Contact {...props} />
  </ContactContextProvider>
)

export default ContactWithContext
