import { PartnerAccount } from 'lib/models/partner-account'

import Table from 'components/table'
import Row from './row'

const AccountsTable = ({
  accounts,
}: {
  accounts: PartnerAccount[]
}): React.ReactElement => {
  if (!accounts || accounts.length === 0) {
    return <p>No Spotlight users linked to this partner.</p>
  }

  return (
    <Table striped>
      {accounts.map(({ id, email }) => {
        return <Row key={id} id={id} email={email} />
      })}
    </Table>
  )
}

export default AccountsTable
