import { ReactElement, useEffect } from 'react'
import styled from 'styled-components'
import { Grid, H, Wrapper } from '@farewill/ui'
import { FONT, GTR } from '@farewill/ui/tokens'
import { Link } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'

import { formatToHuman, getFullNameWithPreferredName } from 'utils/helpers'

import { Uppercase } from 'components/styled/uppercase'
import Badge from 'components/styled/badge'
import NavigationTabs from 'components/navigation-tabs'
import PhoneAndEmailContacts from 'components/phone-email-contacts'
import PrivateRoute from 'components/private-route'
import NotFound from 'routes/not-found'
import { LEAD_STATUS } from 'utils/enums'

import SummaryOfLead from './summary-of-lead'
import History from './history'
import FuneralForm from './funeral-form'
import ProbateForm from './probate-form'
import WillForm from './will-form'
import LpaForm from './lpa-form'
import FuneralPlanForm from './funeral-plan-form'
import LeadEventForm from './lead-event-form'
import { LeadContextProvider, useLeadContext } from './context'
import LinkToExternalSystem from './link-to-external-system'
import MemberBadge from 'components/member-badge'

const StyledDivider = styled.span`
  font-weight: ${FONT.WEIGHT.REGULAR};
`

const StyledLeadContactInfo = styled(Wrapper)`
  display: flex;
  justify-content: space-between;

  & > div {
    display: flex;
    gap: ${GTR.XS};
  }
`

const StyledTitleWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  gap: ${GTR.XS};
`

const StyledDetails = styled(Uppercase)`
  display: flex;
  gap: ${GTR.XS};
  align-items: center;
`

const PRODUCT_FORMS = {
  probate: ProbateForm,
  funeral: FuneralForm,
  will: WillForm,
  lpa: LpaForm,
  funeral_plan: FuneralPlanForm,
}

const Lead = ({ match }: LeadWithContextProps): ReactElement => {
  const path = match.path
  const leadId = match.params.id

  const { fetchLead, lead, isLeadLoading } = useLeadContext()

  useEffect(() => {
    fetchLead()
  }, [fetchLead, leadId])

  const noLead = isEmpty(lead)

  if (isLeadLoading && noLead) return <Uppercase>Loading...</Uppercase>

  if (noLead) return <NotFound />

  const { attributes } = lead
  const NAV_LINKS = [
    { label: 'History', url: `/leads/${leadId}/history` },
    { label: 'Quote', url: `/leads/${leadId}` },
  ]

  const statusBadgeState = (status: string) => {
    if (status === LEAD_STATUS.OPEN || status === LEAD_STATUS.QUALIFIED) {
      return 'success'
    } else if (status === LEAD_STATUS.CONVERTED) {
      return 'info'
    }
    return 'error'
  }

  return (
    <Grid>
      <Grid.Item
        span={8}
        data-sticky-content="lead"
        style={{ alignSelf: 'flex-start' }}
        padding={[GTR.XL, 0, 0]}
      >
        <Wrapper separator>
          <StyledDetails>
            Lead <StyledDivider>|</StyledDivider>
            {formatToHuman(attributes.product)}
            {attributes.status && (
              <Badge $state={statusBadgeState(attributes.status)}>
                {attributes.status}
              </Badge>
            )}
          </StyledDetails>
          <StyledTitleWrapper margin={[0, 0, 'XS']}>
            <H size="XL" decorative margin={0}>
              <Link to={`/contacts/${attributes.contact.id}`}>
                {getFullNameWithPreferredName(attributes.contact) || 'New lead'}
              </Link>
            </H>
            {attributes?.contact?.membership?.number && <MemberBadge />}
          </StyledTitleWrapper>
          <StyledLeadContactInfo>
            <Wrapper>
              <PhoneAndEmailContacts contact={attributes.contact} />
            </Wrapper>

            <LinkToExternalSystem lead={attributes} />
          </StyledLeadContactInfo>

          <SummaryOfLead attributes={attributes} />
        </Wrapper>
        <Wrapper data-testid="lead-navigation-bar" separator>
          <NavigationTabs links={NAV_LINKS} />
        </Wrapper>
        <Wrapper separator>
          <PrivateRoute
            exact
            path={path}
            component={PRODUCT_FORMS[attributes.product]}
            leadId={leadId}
            attributes={attributes}
            disabled={attributes.status === 'converted'}
          />
          <PrivateRoute
            path={`${path}/history`}
            component={History}
            leadId={leadId}
            lead={lead}
          />
        </Wrapper>
      </Grid.Item>
      <Grid.Item span={4}>
        <LeadEventForm />
      </Grid.Item>
    </Grid>
  )
}

type LeadWithContextProps = {
  match: {
    path: string
    params: {
      id: string
    }
  }
}

const LeadWithContext = (props: LeadWithContextProps) => (
  <LeadContextProvider leadId={props.match.params.id}>
    <Lead {...props} />
  </LeadContextProvider>
)
export default LeadWithContext
