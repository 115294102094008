import { Grid, H } from '@farewill/ui'
import { useFormikContext } from 'formik'

import CharityInput from 'components/form/charity-input'
import RadioGroup from 'components/form/radio-group'
import { FuneralPlanLeadAttributes } from 'lib/models/lead'

const FORM_FIELDS_NAMES = {
  WANT_TO_RAISE_MONEY_FOR_CHARITY: 'wantToRaiseMoneyForCharity',
} as const

export type SingleCharityValue = {
  name: string
  number?: string
}

export type HandleCharityChange = (charity: SingleCharityValue) => void

const RaisingMoneyForCharity = () => {
  const { values, handleSubmit, setFieldValue } =
    useFormikContext<FuneralPlanLeadAttributes>()

  const handleCharityChange: HandleCharityChange = (charity) => {
    setFieldValue('quoteAttributes.charity', charity)
    handleSubmit()
  }

  return (
    <>
      <Grid.Item data-testid="raising-money-for-charity">
        <H size="S">Raising money for charity</H>
        <Grid style={{ gridAutoFlow: 'row' }}>
          <Grid.Item>
            <RadioGroup
              name={`quoteAttributes.${FORM_FIELDS_NAMES.WANT_TO_RAISE_MONEY_FOR_CHARITY}`}
              label="Do they want their loved ones to raise money for a charity in their memory?"
              handleSave={(values) => {
                if (values.value === 'false') {
                  setFieldValue('quoteAttributes.charity', null)
                }
                handleSubmit()
              }}
              options={[
                { label: 'Yes', value: 'true' },
                {
                  label: 'No',
                  value: 'false',
                },
              ]}
            />
          </Grid.Item>
          {values.quoteAttributes.wantToRaiseMoneyForCharity === 'true' && (
            <Grid.Item>
              <CharityInput
                name="charity"
                label="Search for charity name"
                onCharityChanged={handleCharityChange}
                charityValues={values.quoteAttributes.charity}
              />
            </Grid.Item>
          )}
        </Grid>
      </Grid.Item>
    </>
  )
}

export default RaisingMoneyForCharity
