import { ReactElement } from 'react'
import { useFormikContext } from 'formik'
import { Grid, H } from '@farewill/ui'

import { FuneralPlanLeadAttributes } from 'lib/models/lead'
import RadioGroup from 'components/form/radio-group'
import { CircularRadioGroup, Input } from 'components/form'
import { getYearsElapsed } from 'utils/helpers'

import { useLeadContext } from '../context'

const FORM_FIELDS_NAMES = {
  BUYING_FOR_THEMSELVES: 'purchaserIsCoveredIndividual',
  COVERED_INDIVIDUAL_CONTACT: 'coveredIndividual',
  NOMINATED_REPRESENTATIVE: 'hasNominatedRepresentative',
  NOMINATED_REPRESENTATIVE_CONTACT: 'nominatedRepresentative',
  NOMINATED_REPRESENTATIVE_CALLER: 'purchaserIsNominatedRepresentative',
} as const

const CoveredIndividual = (): ReactElement => {
  const { values, handleSubmit } = useFormikContext<FuneralPlanLeadAttributes>()
  const { updateContact } = useLeadContext()

  const customerDateOfBirth = values.contact?.dateOfBirth
  const coveredIndividualDateOfBirth =
    values.quoteAttributes.coveredIndividual?.dateOfBirth

  return (
    <>
      <H size="S">The plan</H>
      <Grid data-testid="covered-individual">
        <Grid.Item>
          <RadioGroup
            name={`quoteAttributes.${FORM_FIELDS_NAMES.BUYING_FOR_THEMSELVES}`}
            label="Are they buying the plan for themself?"
            options={[
              { label: 'Yes', value: 'true' },
              {
                label: 'No',
                value: 'false',
              },
            ]}
            handleSave={() => handleSubmit()}
          />
        </Grid.Item>
        {String(
          values.quoteAttributes[FORM_FIELDS_NAMES.BUYING_FOR_THEMSELVES]
        ) === 'true' && (
          <>
            <Grid.Item>
              <H size="S">Identifying information</H>
            </Grid.Item>
            <Grid.Item spanFromL={6}>
              <Input
                type="date"
                label="Date of birth"
                name="contact.dateOfBirth"
                handleSave={updateContact}
                hint={
                  customerDateOfBirth &&
                  `Age: ${getYearsElapsed({
                    dateOfBirth: customerDateOfBirth,
                  })}`
                }
              />
            </Grid.Item>

            <Grid.Item>
              <CircularRadioGroup
                label="Sex"
                name={`quoteAttributes.${FORM_FIELDS_NAMES.COVERED_INDIVIDUAL_CONTACT}.sex`}
                inline
                options={[
                  { label: 'Male', value: 'male' },
                  { label: 'Female', value: 'female' },
                ]}
                handleSave={() => handleSubmit()}
              />
            </Grid.Item>
          </>
        )}
        {String(
          values.quoteAttributes[FORM_FIELDS_NAMES.BUYING_FOR_THEMSELVES]
        ) === 'false' && (
          <>
            <Grid.Item>
              <H size="S">Covered Individual</H>
              <Grid style={{ gridAutoFlow: 'row' }} margin={[0, 0, 'M']}>
                <Grid.Item spanFromL={6} startColumn={1}>
                  <Input
                    label="First name"
                    name={`quoteAttributes.${FORM_FIELDS_NAMES.COVERED_INDIVIDUAL_CONTACT}.firstName`}
                    handleSave={() => handleSubmit()}
                    allowCopyToClipboard
                  />
                </Grid.Item>
                <Grid.Item spanFromL={6}>
                  <Input
                    label="Last name"
                    name={`quoteAttributes.${FORM_FIELDS_NAMES.COVERED_INDIVIDUAL_CONTACT}.lastName`}
                    handleSave={() => handleSubmit()}
                    allowCopyToClipboard
                  />
                </Grid.Item>
              </Grid>
              <Grid margin={[0, 0, 'M']}>
                <Grid.Item spanFromL={6}>
                  <Input
                    name={`quoteAttributes.${FORM_FIELDS_NAMES.COVERED_INDIVIDUAL_CONTACT}.dateOfBirth`}
                    type="date"
                    label="Date of birth"
                    hint={
                      coveredIndividualDateOfBirth &&
                      `Age: ${getYearsElapsed({
                        dateOfBirth: coveredIndividualDateOfBirth,
                      })}`
                    }
                    handleSave={() => handleSubmit()}
                  />
                </Grid.Item>
                <Grid.Item>
                  <CircularRadioGroup
                    label="Sex"
                    name={`quoteAttributes.${FORM_FIELDS_NAMES.COVERED_INDIVIDUAL_CONTACT}.sex`}
                    inline
                    options={[
                      { label: 'Male', value: 'male' },
                      { label: 'Female', value: 'female' },
                    ]}
                    handleSave={() => handleSubmit()}
                  />
                </Grid.Item>
              </Grid>
            </Grid.Item>
          </>
        )}
      </Grid>
    </>
  )
}

export default CoveredIndividual
