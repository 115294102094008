import { useFormikContext } from 'formik'
import { Grid, H, P } from '@farewill/ui'

import { YES_NO_OPTIONS } from 'utils/enums'
import { FuneralPlanLeadAttributes } from 'lib/models/lead'
import Input from 'components/form/input'
import RadioGroup from 'components/form/radio-group'
import SectionStatus from 'components/section-status'

const QualifyingQuestions = (): React.ReactElement => {
  const { values, handleSubmit } = useFormikContext<FuneralPlanLeadAttributes>()

  return (
    <>
      <H size="S">Check the plan holder is eligible</H>
      <Grid>
        <Grid.Item>
          <RadioGroup
            name="quoteAttributes.coveredIndividualIsOverEighteen"
            options={YES_NO_OPTIONS}
            label="Is the plan for a person who is over the age of 18?"
            handleSave={() => handleSubmit()}
          />
        </Grid.Item>
        {String(values.quoteAttributes.coveredIndividualIsOverEighteen) ===
          'false' && (
          <Grid.Item>
            <SectionStatus complete heading="Not eligible for plan">
              <P>
                We only offer funeral plans for people aged 18 and over. If
                they're expected to die soon, refer them to at-need funerals.
              </P>
            </SectionStatus>
          </Grid.Item>
        )}
        <Grid.Item>
          <RadioGroup
            name="quoteAttributes.livesInEnglandOrWales"
            options={YES_NO_OPTIONS}
            label="Is the plan for a person who lives in mainland England or Wales?"
            handleSave={() => handleSubmit()}
          />
        </Grid.Item>
        {String(values.quoteAttributes.livesInEnglandOrWales) === 'false' && (
          <>
            <Grid.Item spanFromL={6}>
              <Input
                name="quoteAttributes.residence"
                label="Where do they live? (Optional)"
                handleSave={() => handleSubmit()}
              />
            </Grid.Item>
            <Grid.Item>
              <SectionStatus complete heading="Not eligible for plan">
                <P>
                  We only offer funeral plans in England and Wales at the
                  moment, but we can let them know when we cover their country.
                </P>
              </SectionStatus>
            </Grid.Item>
          </>
        )}
        <Grid.Item>
          <RadioGroup
            name="quoteAttributes.alreadyHasFuneralPlan"
            options={YES_NO_OPTIONS}
            label="Is there already a funeral plan in place, or any other provisions to fund the funeral for this person?"
            handleSave={() => handleSubmit()}
          />
        </Grid.Item>
        {String(values.quoteAttributes.alreadyHasFuneralPlan) === 'true' && (
          <Grid.Item>
            <SectionStatus complete heading="Discuss options with customer">
              <P>
                Explain that they might be paying twice for their funeral, which
                will be more expensive.
              </P>
            </SectionStatus>
          </Grid.Item>
        )}
        <Grid.Item>
          <RadioGroup
            name="quoteAttributes.imminentNeed"
            options={YES_NO_OPTIONS}
            label="Is the plan for someone who has received a terminal diagnosis or is receiving end of life care?"
            handleSave={() => handleSubmit()}
          />
        </Grid.Item>
        {String(values.quoteAttributes.imminentNeed) === 'true' && (
          <Grid.Item>
            <SectionStatus complete heading="Discuss options with customer">
              <P>
                An at-need funeral might be a better option for them, because
                it’s less expensive. One of the reasons that people buy a plan
                is to protect them against rising prices, which are less likely
                within a year or two.
              </P>
              <P>
                Make sure they understand the difference between an at-need
                funeral and a plan.
              </P>
            </SectionStatus>
          </Grid.Item>
        )}
      </Grid>
    </>
  )
}

export default QualifyingQuestions
