import { Form, Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Input, Wrapper } from '@farewill/ui'

import apiRequest from 'lib/axios/api-request'
import { hideModal } from 'state/actions'

export const AddAccountModal = (config: { config: { partnerId: string } }) => {
  const { partnerId } = config.config
  const dispatch = useDispatch()
  const history = useHistory()

  const initialValues = {
    email: '',
  }

  const handleSubmit = async (values: { email: string }) => {
    try {
      await apiRequest({
        url: `/api/partner-accounts`,
        method: 'PUT',
        data: { email: values.email, appMetadata: { partnerId } },
      })
      dispatch(hideModal())
      history.go(0) // Refresh page to re-retrieve accounts after add
    } catch (err) {
      console.error(`Adding email ${values.email}`, err)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => handleSubmit(values)}
    >
      {(values) => (
        <Form>
          <Wrapper margin={[0, 0, 'M']}>
            <Input
              label="Email"
              name="email"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                values.values.email = e.target.value
              }}
            />
          </Wrapper>

          <Button.Primary type="submit" stretch>
            Add user
          </Button.Primary>
        </Form>
      )}
    </Formik>
  )
}
