import { Grid, H, InfoCircleIcon, Wrapper } from '@farewill/ui'
import { useFormikContext } from 'formik'
import { FormEvent } from 'react'

import AddressInputWrapper from 'components/address'
import { Checkbox, SelectInput } from 'components/form'
import ContactEmailInput from 'components/form/contact-email-input'
import Input from 'components/form/input'
import { Selected } from 'components/form/select-input/types'
import Tooltip from 'components/tooltip'
import { FuneralPlanLeadAttributes } from 'lib/models/lead'
import {
  FAREWILL_PRODUCTS,
  FUNERAL_PLAN_LEAD_HEARD_ABOUT_FAREWILL_VIA_OPTIONS,
  LEAD_SOURCE_IDENTIFIER_OPTIONS,
  LEAD_SOURCE_TYPE_OPTIONS,
} from 'utils/enums'
import { strToBool } from 'utils/helpers'

import { useLeadContext } from '../context'
import PartnersAndDiscounts from '../partners-and-discounts'

const ContactDetails = (): React.ReactElement => {
  const { fetchLead, updateContact } = useLeadContext()
  const { values, handleSubmit, setFieldValue } =
    useFormikContext<FuneralPlanLeadAttributes>()

  const sourceIdentifierOptions =
    LEAD_SOURCE_IDENTIFIER_OPTIONS[
      values.sourceType as keyof typeof LEAD_SOURCE_IDENTIFIER_OPTIONS
    ]

  const resetSourceIdentifierAndSubmit = (value: {
    name: string
    value: Selected
  }) => {
    setFieldValue('sourceIdentifier', null)
    handleSubmit(value as unknown as FormEvent<HTMLFormElement>)
  }

  return (
    <>
      <H size="S">The caller</H>
      <Grid style={{ gridAutoFlow: 'row' }}>
        <Grid.Item spanFromL={6}>
          <Input
            label="Preferred name (optional)"
            hint="We’ll use this in conversation, and at the start of emails and letters (e.g Hello Mr Smith, or Hello Paul)."
            name="contact.preferredName"
            handleSave={updateContact}
          />
        </Grid.Item>
        <Grid.Item spanFromL={2} startColumnFromL={1}>
          <Input
            label="Title"
            name="contact.title"
            handleSave={updateContact}
          />
        </Grid.Item>
        <Grid.Item spanFromL={5} startColumnFromL={3}>
          <Input
            label="First name"
            name="contact.firstName"
            handleSave={updateContact}
            allowCopyToClipboard
          />
        </Grid.Item>
        <Grid.Item spanFromL={5}>
          <Input
            label="Last name"
            name="contact.lastName"
            handleSave={updateContact}
            allowCopyToClipboard
          />
        </Grid.Item>
        <Grid.Item spanFromL={6}>
          <Input
            label="Phone number"
            name="contact.phoneNumber"
            handleSave={updateContact}
            allowCopyToClipboard
          />
        </Grid.Item>
        <Grid.Item spanFromL={6}>
          <ContactEmailInput
            contactId={values.contact.id}
            handleSave={async ({ name, value }) => {
              const updatedLeadResponse = await updateContact({ name, value })

              const updatedContact = updatedLeadResponse.data.attributes.contact

              /**
               * This means the email change has been recognised as already in use
               * and triggered the contact to be merged into an existing contact.
               * We need to update Formik's value for the contactId, otherwise it
               * will refer to the deleted contact.
               *
               * TODO: each product has its own version of this component so this
               * implementation is currently duplicated across all of them.
               */
              if (values.contact.id !== updatedContact.id) {
                setFieldValue('contactId', updatedContact.id)
                setFieldValue('contact', updatedContact)
              }
            }}
            hasRelatedAccount={!!values.contact.accountUuid}
            inputName="contact.email"
            allowCopyToClipboard
          />
        </Grid.Item>
        <Grid.Item spanFromL={6}>
          <Checkbox
            label={
              <>
                <span>Customer requested postal service</span>
                <Tooltip content="Check this if the customer does not have an email address and wants all communication to be sent via post">
                  <Wrapper
                    padding={[0, 0, 0, 'XS']}
                    as={InfoCircleIcon}
                    size="S"
                  />
                </Tooltip>
              </>
            }
            name="quoteAttributes.isPostalService"
            checked={strToBool(values.quoteAttributes.isPostalService)}
            handleSave={() => handleSubmit()}
          />
        </Grid.Item>
        <Grid.Item>
          <AddressInputWrapper
            data={values.contact.addresses[0]}
            label="Address"
            name="contact.addresses"
            newAddressAttrs={{
              relatedResource: 'contact',
              relatedId: values.contactId,
              relation: 'addresses',
            }}
            onSave={() => {
              /**
               * We need to fetch the lead again to get the updated contact
               * with the new address as we rely on the address to allow the user
               * to send a brochure to the customer.
               */
              fetchLead()
            }}
          />
        </Grid.Item>
        <Grid.Item spanFromL={6}>
          <SelectInput
            label="Lead Type"
            name="sourceType"
            handleSave={resetSourceIdentifierAndSubmit}
            options={LEAD_SOURCE_TYPE_OPTIONS}
          />
        </Grid.Item>
        {sourceIdentifierOptions && (
          <Grid.Item spanFromL={6}>
            <SelectInput
              label="Source"
              name="sourceIdentifier"
              options={sourceIdentifierOptions}
              handleSave={() => handleSubmit()}
            />
          </Grid.Item>
        )}
        <Grid.Item>
          <PartnersAndDiscounts product={FAREWILL_PRODUCTS.FUNERAL_PLAN} />
        </Grid.Item>
        <Grid.Item spanFromL={6}>
          <SelectInput
            label="Where did they hear about Farewill?"
            name="quoteAttributes.heardAboutFarewillVia"
            options={FUNERAL_PLAN_LEAD_HEARD_ABOUT_FAREWILL_VIA_OPTIONS}
            handleSave={() => handleSubmit()}
          />
        </Grid.Item>
      </Grid>
    </>
  )
}

export default ContactDetails
