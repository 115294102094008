import { ReactElement, useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import { Button, Grid, H } from '@farewill/ui'

import { FuneralPlanAttributes } from 'lib/models/funeral-plan'
import { AddressInput, CircularRadioGroup, Input } from 'components/form'
import { NominatedRepresentative as NominatedRepresentativeModel } from '../../lib/models/lead'
import { useDispatch } from 'react-redux'
import { showModal, updateFuneralPlan } from 'state/actions'
import ResetNominatedRepresentativeModal from './reset-nominated-representative-modal'
import { CaseDetailsSectionProps } from './types'
import { useParams } from 'react-router-dom'

const FORM_FIELDS_NAMES = {
  BUYING_FOR_THEMSELVES: 'purchaserIsCoveredIndividual',
  COVERED_INDIVIDUAL_CONTACT: 'coveredIndividualDetails',
  NOMINATED_REPRESENTATIVE: 'hasNominatedRepresentative',
  NOMINATED_REPRESENTATIVE_CONTACT: 'nominatedRepresentative',
  NOMINATED_REPRESENTATIVE_CALLER: 'purchaserIsNominatedRepresentative',
} as const

const NominatedRepresentative = ({
  handleSave,
}: CaseDetailsSectionProps): ReactElement => {
  const { values, setFieldValue } = useFormikContext<FuneralPlanAttributes>()
  const dispatch = useDispatch()
  const { id: funeralPlanId } = useParams<{ id: string }>()
  const [hasNominatedRepresentative, setHasNominatedRepresentative] = useState(
    values?.nominatedRepresentative &&
      Object.keys(values.nominatedRepresentative).length > 0
  )

  useEffect(() => {
    setHasNominatedRepresentative(
      values?.nominatedRepresentative &&
        Object.keys(values.nominatedRepresentative).length > 0
    )
  }, [values])

  const resetNominatedRepresentative = () => {
    if (
      values?.nominatedRepresentative &&
      Object.keys(values.nominatedRepresentative).length > 0
    ) {
      dispatch(
        showModal({
          component: ResetNominatedRepresentativeModal,
          headingText: 'Remove Nominated Representative?',
          config: {
            resetNominatedRepresentative: async () => {
              dispatch(
                updateFuneralPlan(funeralPlanId, {
                  nominatedRepresentative: {} as NominatedRepresentativeModel,
                })
              )
            },
          },
        })
      )
    } else {
      setHasNominatedRepresentative(false)
    }
  }

  return (
    <Grid.Item data-testid="nominated-representative">
      <H size="S">Nominated representative</H>
      {hasNominatedRepresentative && (
        <Grid style={{ gridAutoFlow: 'row' }}>
          <Grid.Item spanFromL={6} startColumn={1}>
            <Input
              label="First name"
              name={`${FORM_FIELDS_NAMES.NOMINATED_REPRESENTATIVE_CONTACT}.firstName`}
              handleSave={handleSave}
            />
          </Grid.Item>
          <Grid.Item spanFromL={6}>
            <Input
              label="Last name"
              name={`${FORM_FIELDS_NAMES.NOMINATED_REPRESENTATIVE_CONTACT}.lastName`}
              handleSave={handleSave}
            />
          </Grid.Item>
          <Grid.Item spanFromL={6}>
            <Input
              label="Phone number"
              name={`${FORM_FIELDS_NAMES.NOMINATED_REPRESENTATIVE_CONTACT}.phoneNumber`}
              handleSave={handleSave}
            />
          </Grid.Item>
          <Grid.Item spanFromL={6}>
            <Input
              label="Email"
              name={`${FORM_FIELDS_NAMES.NOMINATED_REPRESENTATIVE_CONTACT}.email`}
              type="email"
              handleSave={handleSave}
            />
          </Grid.Item>
          <Grid.Item spanFromL={6}>
            <AddressInput
              label="Address"
              name={`${FORM_FIELDS_NAMES.NOMINATED_REPRESENTATIVE_CONTACT}`}
              onAddressChanged={(args) => {
                setFieldValue(
                  `${FORM_FIELDS_NAMES.NOMINATED_REPRESENTATIVE_CONTACT}.addressFields`,
                  args
                )
                handleSave({
                  name: `${FORM_FIELDS_NAMES.NOMINATED_REPRESENTATIVE_CONTACT}.addressFields`,
                  value: args,
                })
              }}
              addressValues={
                values[FORM_FIELDS_NAMES.NOMINATED_REPRESENTATIVE_CONTACT]
                  ?.addressFields
              }
            />
          </Grid.Item>
          <Grid.Item>
            <CircularRadioGroup
              label="Can we contact this person directly?"
              name={`${FORM_FIELDS_NAMES.NOMINATED_REPRESENTATIVE_CONTACT}.canBeContactedDirectly`}
              inline
              options={[
                { value: 'true', label: 'Yes' },
                { value: 'false', label: 'No' },
              ]}
              handleSave={handleSave}
            />
          </Grid.Item>
          <Grid.Item>
            <Button.Underline
              flush
              onClick={() => resetNominatedRepresentative()}
            >
              Remove Nominated Representative
            </Button.Underline>
          </Grid.Item>
        </Grid>
      )}
      {!hasNominatedRepresentative && (
        <Grid>
          <Grid.Item>
            <Button.Primary
              flush
              onClick={() => setHasNominatedRepresentative(true)}
            >
              Add Nominated Representative
            </Button.Primary>
          </Grid.Item>
        </Grid>
      )}
    </Grid.Item>
  )
}

const CoveredIndividual = ({
  handleSave,
}: CaseDetailsSectionProps): ReactElement => {
  return (
    <>
      <Grid data-testid="covered-individual">
        <Grid.Item>
          <H size="S">Covered Individual</H>
          <Grid style={{ gridAutoFlow: 'row' }} margin={[0, 0, 'M']}>
            <Grid.Item spanFromL={6} startColumn={1}>
              <Input
                label="First name"
                name={`${FORM_FIELDS_NAMES.COVERED_INDIVIDUAL_CONTACT}.firstName`}
                handleSave={handleSave}
              />
            </Grid.Item>
            <Grid.Item spanFromL={6}>
              <Input
                label="Last name"
                name={`${FORM_FIELDS_NAMES.COVERED_INDIVIDUAL_CONTACT}.lastName`}
                handleSave={handleSave}
              />
            </Grid.Item>
          </Grid>
          <H size="S">Identifying Information</H>
          <Grid margin={[0, 0, 'M']}>
            <Grid.Item spanFromL={6}>
              <Input
                name={`${FORM_FIELDS_NAMES.COVERED_INDIVIDUAL_CONTACT}.dateOfBirth`}
                type="date"
                label="Date of birth"
                handleSave={handleSave}
              />
            </Grid.Item>
            <Grid.Item>
              <CircularRadioGroup
                label="Sex"
                name={`${FORM_FIELDS_NAMES.COVERED_INDIVIDUAL_CONTACT}.sex`}
                inline
                options={[
                  { label: 'Male', value: 'male' },
                  { label: 'Female', value: 'female' },
                ]}
                handleSave={handleSave}
              />
            </Grid.Item>
          </Grid>
        </Grid.Item>
        <NominatedRepresentative handleSave={handleSave} />
      </Grid>
    </>
  )
}

export default CoveredIndividual
