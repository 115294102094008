import Dinero from 'dinero.js'

import {
  FuneralLeadAttributes,
  FuneralPlanLeadAttributes,
  LeadAttributes,
  LpaLeadAttributes,
  ProbateLeadAttributes,
  WillLeadAttributes,
} from 'lib/models/lead'
import {
  CustomerRelationship,
  LEAD_PARTNER_TYPES,
  RELATIONSHIP_TO_CUSTOMER,
} from 'utils/enums'
import {
  formatDateTimeToUTC,
  strToBool,
  convertPoundsToPence,
  ensureNumberOrNull,
  formatEmptyStringToUndefined,
} from 'utils/helpers'
import { DEFINED_TIMESLOTS } from './constants'
import { DefinedTimeslot } from './types'

// common methods
const getPartnerId = (values: LeadAttributes) => {
  const { partnerType, partnerId } = values
  if (!partnerType || partnerType === LEAD_PARTNER_TYPES.none) return null
  return partnerId || null
}

const getCommonData = (values: LeadAttributes) => ({
  sourceType: values.sourceType || null,
  sourceIdentifier: values.sourceIdentifier || null,
  partnerType: values.partnerType || null,
  partnerId: getPartnerId(values),
  voucherCode: values.voucherCode || null,
})

export const getDefinedTimeslot = (
  timestamp: string
): DefinedTimeslot | undefined => {
  const timeInHoursMinutesSeconds = timestamp.split('T')[1].split('.')[0]
  return DEFINED_TIMESLOTS.find(
    (definedTimestamp) => definedTimestamp.value === timeInHoursMinutesSeconds
  )
}

/** this method formats a date as an ISO string in local time, using tthe local
 * computer timezone to calculate the offset in milliseconds from UTC. The method
 * helps us display the time correctly during BST. */
export const toLocalISOString = (date: Date) => {
  const tzoffset = date.getTimezoneOffset() * 60000
  const localISOTime = new Date(date.getTime() - tzoffset)
    .toISOString()
    .slice(0, -1)
  return localISOTime
}

// funeral methods
export const PAYMENT_METHODS = {
  invoice: 'invoice',
  card: 'card',
  invoiceAndCard: 'invoiceAndCard',
}

const getFuneralInvoicePrice = (values: FuneralLeadAttributes) => {
  const price = ensureNumberOrNull(values.quoteAttributes.invoice?.amount)
  return convertPoundsToPence(price)
}

const isInvoicePaymentMethod = (values: FuneralLeadAttributes) =>
  [PAYMENT_METHODS.invoice, PAYMENT_METHODS.invoiceAndCard].includes(
    values.quoteAttributes.paymentMethod as string
  )

const getFuneralsPaymentAmount = (values: FuneralLeadAttributes) => {
  const numberAmount = ensureNumberOrNull(values.quoteAttributes.paymentAmount)
  if (typeof numberAmount === 'number') {
    return convertPoundsToPence(numberAmount)
  } else if (values.quoteAttributes.paymentReference) {
    return values.calculatedPrice
  }
}

const getFuneralData = (values: FuneralLeadAttributes) => {
  const urnTypes = values?.quoteAttributes.urnTypes?.filter(Boolean) || []
  return {
    ...getCommonData(values),
    calculatedPrice:
      typeof values.calculatedPrice === 'number'
        ? values.calculatedPrice
        : null,
    quoteAttributes: {
      funeralType: values.quoteAttributes.funeralType || null,
      hasHazardousImplant: values.quoteAttributes.hasHazardousImplant || null,
      coffinType: values.quoteAttributes.coffinType || null,
      deceasedReferredToCoroner: strToBool(
        values.quoteAttributes.deceasedReferredToCoroner
      ),
      deceasedInMortuary: strToBool(values.quoteAttributes.deceasedInMortuary),
      deceasedWeighsOver20Stone: strToBool(
        values.quoteAttributes.deceasedWeighsOver20Stone
      ),
      paymentAmount: getFuneralsPaymentAmount(values),
      paymentReference: values.quoteAttributes.paymentReference || null,
      alreadyDeceased: strToBool(values.quoteAttributes.alreadyDeceased),
      customerIsArrangingTheirOwnFuneral: strToBool(
        values.quoteAttributes.customerIsArrangingTheirOwnFuneral
      ),
      title: values.quoteAttributes.title || null,
      firstName: values.quoteAttributes.firstName || null,
      lastName: values.quoteAttributes.lastName || null,
      relationshipToCustomer:
        values.quoteAttributes.relationshipToCustomer || null,
      customerRefersToThemAs:
        values.quoteAttributes.customerRefersToThemAs || null,
      dateOfBirth: values.quoteAttributes.dateOfBirth || null,
      dateOfDeath: values.quoteAttributes.dateOfDeath || null,
      deceasedLocationCategory:
        values.quoteAttributes.deceasedLocationCategory || null,
      ashesInstruction: values.quoteAttributes.ashesInstruction || 'deliver',
      ashesSplitting: strToBool(values.quoteAttributes.ashesSplitting),
      ashesSplittingPortions:
        values.quoteAttributes.ashesSplittingPortions || null,
      treeType: values.quoteAttributes.treeType || null,
      treePlaqueMessage: values.quoteAttributes.treePlaqueMessage || null,
      urnTypes,
      hasObjectionsToFuneral: strToBool(
        values.quoteAttributes.hasObjectionsToFuneral
      ),
      needsSupportFromDwp: strToBool(
        values.quoteAttributes.needsSupportFromDwp
      ),
      dwpSupportConfirmed: strToBool(
        values.quoteAttributes.dwpSupportConfirmed
      ),
      paymentMethod: values.quoteAttributes.paymentMethod || null,
      invoice: isInvoicePaymentMethod(values)
        ? {
            externalReferenceId:
              values.quoteAttributes.invoice?.externalReferenceId || null,
            reference: values.quoteAttributes.invoice?.reference || null,
            date: values.quoteAttributes.invoice?.date || null,
            amount: getFuneralInvoicePrice(values),
            paidBy: values.quoteAttributes.invoice?.paidBy || null,
          }
        : null,
      deathCountryId: values.quoteAttributes.deathCountryId || null,
    },
  }
}

const isCharitySelected = ({ quoteAttributes }: FuneralPlanLeadAttributes) => {
  if (quoteAttributes.wantToRaiseMoneyForCharity === null) {
    return null
  }

  const wantToRaiseMoneyForCharity = strToBool(
    quoteAttributes.wantToRaiseMoneyForCharity
  )

  if (wantToRaiseMoneyForCharity === false) {
    return wantToRaiseMoneyForCharity
  }

  return quoteAttributes.charity?.name ? true : null
}

// funeral plan methods
const getFuneralPlanData = (values: FuneralPlanLeadAttributes) => ({
  ...getCommonData(values),
  quoteAttributes: {
    heardAboutFarewillVia: values.quoteAttributes.heardAboutFarewillVia || null,
    imminentNeed: strToBool(values.quoteAttributes.imminentNeed) ?? null,
    paymentType: values.quoteAttributes.paymentType || null,
    paymentAmount: values.quoteAttributes.paymentAmount || null,
    paymentReference: values.quoteAttributes.paymentReference || null,
    journeyType: values.quoteAttributes.journeyType || null,
    residence: values.quoteAttributes.residence || null,
    livesInEnglandOrWales:
      strToBool(values.quoteAttributes.livesInEnglandOrWales) ?? null,
    purchaserIsCoveredIndividual:
      strToBool(values.quoteAttributes.purchaserIsCoveredIndividual) ?? null,
    packageId: values.quoteAttributes.packageId || null,
    coveredIndividualIsOverEighteen:
      strToBool(values.quoteAttributes.coveredIndividualIsOverEighteen) ?? null,
    alreadyHasFuneralPlan:
      strToBool(values.quoteAttributes.alreadyHasFuneralPlan) ?? null,
    vulnerabilities: values.quoteAttributes.vulnerabilities || null,
    hasNominatedRepresentative:
      strToBool(values.quoteAttributes.hasNominatedRepresentative) ?? null,
    purchaserIsNominatedRepresentative:
      strToBool(values.quoteAttributes.purchaserIsNominatedRepresentative) ??
      null,
    isPostalService: strToBool(values.quoteAttributes.isPostalService) ?? false,
    coupledLeadId: values.quoteAttributes.coupledLeadId || null,
    nominatedRepresentative:
      {
        firstName:
          values.quoteAttributes?.nominatedRepresentative?.firstName || null,
        lastName:
          values.quoteAttributes?.nominatedRepresentative?.lastName || null,
        phoneNumber:
          values.quoteAttributes?.nominatedRepresentative?.phoneNumber || null,
        email: values.quoteAttributes?.nominatedRepresentative?.email || null,
        canBeContactedDirectly:
          strToBool(
            values.quoteAttributes?.nominatedRepresentative
              ?.canBeContactedDirectly
          ) ?? null,
        /** we only send the addressFields to the BE if canBeContactedDirectly is true,
          else we send an empty object */
        addressFields: strToBool(
          values.quoteAttributes?.nominatedRepresentative
            ?.canBeContactedDirectly
        )
          ? {
              lineOne:
                values.quoteAttributes?.nominatedRepresentative?.addressFields
                  ?.lineOne || null,
              lineTwo:
                values.quoteAttributes?.nominatedRepresentative?.addressFields
                  ?.lineTwo || null,
              city:
                values.quoteAttributes?.nominatedRepresentative?.addressFields
                  ?.city || null,
              postalCode:
                values.quoteAttributes?.nominatedRepresentative?.addressFields
                  ?.postalCode || null,
              countryCode:
                values.quoteAttributes?.nominatedRepresentative?.addressFields
                  ?.countryCode || null,
            }
          : {},
      } || null,
    coveredIndividual:
      {
        firstName: values.quoteAttributes?.coveredIndividual?.firstName || null,
        lastName: values.quoteAttributes?.coveredIndividual?.lastName || null,
        dateOfBirth:
          values.quoteAttributes?.coveredIndividual?.dateOfBirth || null,
        sex: values.quoteAttributes?.coveredIndividual?.sex || null,
      } || null,
    charity:
      {
        name: values.quoteAttributes?.charity?.name || null,
        number: values.quoteAttributes?.charity?.number || null,
      } || null,
    wantToRaiseMoneyForCharity: isCharitySelected(values),
  },
})

// lpa methods
const getLpaData = (values: LpaLeadAttributes) => ({
  ...getCommonData(values),
  calculatedPrice: values.calculatedPrice || null,
  quoteAttributes: {
    willCaseId: values.quoteAttributes.willCaseId || null,
    relationshipStatus: values.quoteAttributes.relationshipStatus || null,
    partnerFirstName: values.quoteAttributes.partnerFirstName || null,
    residence: values.quoteAttributes.residence || null,
    lpaType: values.quoteAttributes.lpaType || 'single',
    paymentReference: values.quoteAttributes.paymentReference || null,
    wantsLargePrint: values.quoteAttributes.wantsLargePrint || null,
    price: values.quoteAttributes.price
      ? Dinero({
          amount: Math.round(values.quoteAttributes.price * 100),
        }).getAmount()
      : null,
    factFindCallAt: formatDateTimeToUTC(values.quoteAttributes.factFindCallAt),
    partner: {
      title: values.quoteAttributes.partner?.title || null,
      firstName: values.quoteAttributes.partner?.firstName || null,
      lastName: values.quoteAttributes.partner?.lastName || null,
      dateOfBirth: values.quoteAttributes.partner?.dateOfBirth || null,
      email: values.quoteAttributes.partner?.email || null,
      phoneNumber: values.quoteAttributes.partner?.phoneNumber || null,
      addressFields: {
        lineOne:
          values.quoteAttributes?.partner?.addressFields?.lineOne || null,
        lineTwo:
          values.quoteAttributes?.partner?.addressFields?.lineTwo || null,
        city: values.quoteAttributes?.partner?.addressFields?.city || null,
        postalCode:
          values.quoteAttributes?.partner?.addressFields?.postalCode || null,
        countryCode:
          values.quoteAttributes?.partner?.addressFields?.countryCode || null,
      },
    },
  },
})

// probate methods
const getProbateCalculatedPrice = (values: ProbateLeadAttributes) => {
  const { quotedPrices, quoteAttributes } = values
  return strToBool(quoteAttributes.interestedInEstateAdmin)
    ? quotedPrices?.estateAdmin
    : quotedPrices?.probateOnly
}

const getProbateQuotedPrices = (values: ProbateLeadAttributes) => ({
  probateOnly: values.quotedPrices?.probateOnly || null,
  estateAdmin: values.quotedPrices?.estateAdmin || null,
})

const getProbateData = (values: ProbateLeadAttributes) => ({
  ...getCommonData(values),
  calculatedPrice: getProbateCalculatedPrice(values) || null,
  quotedPrices: getProbateQuotedPrices(values),
  quoteAttributes: {
    funeralId: values.quoteAttributes.funeralId || null,
    relationshipToCustomer:
      values.quoteAttributes.relationshipToCustomer || null,
    otherRelationshipDescription:
      values.quoteAttributes.otherRelationshipDescription || null,
    customerRefersToThemAs:
      values.quoteAttributes.customerRefersToThemAs || null,
    aboveAccountOrShareThreshold: strToBool(
      values.quoteAttributes.aboveAccountOrShareThreshold
    ),
    customerAlreadyKnowsProbateRequired: strToBool(
      values.quoteAttributes.customerAlreadyKnowsProbateRequired
    ),
    ownedProperty: strToBool(values.quoteAttributes.ownedProperty),
    propertyOwnershipType: values.quoteAttributes.propertyOwnershipType || null,
    hadWill: strToBool(values.quoteAttributes.hadWill),
    customerIsExecutor: strToBool(values.quoteAttributes.customerIsExecutor),
    customerIsAdministrator: strToBool(
      values.quoteAttributes.customerIsAdministrator
    ),
    customerIsNextOfKin: strToBool(values.quoteAttributes.customerIsNextOfKin),
    dateOfDeath: values.quoteAttributes.dateOfDeath || null,
    dateOfBirth: values.quoteAttributes.dateOfBirth || null,
    maritalStatus: values.quoteAttributes.maritalStatus || null,
    leftAllToPartner: strToBool(values.quoteAttributes.leftAllToPartner),
    aboveEstateValueThreshold: strToBool(
      values.quoteAttributes.aboveEstateValueThreshold
    ),
    aboveEstatePassingThreshold: strToBool(
      values.quoteAttributes.aboveEstatePassingThreshold
    ),
    widowSpouseLeftEverythingInWill: strToBool(
      values.quoteAttributes.widowSpouseLeftEverythingInWill
    ),
    livedInEnglandOrWales: strToBool(
      values.quoteAttributes.livedInEnglandOrWales
    ),
    aboveOverseasThreshold: strToBool(
      values.quoteAttributes.aboveOverseasThreshold
    ),
    giftsGivenOverThreshold: strToBool(
      values.quoteAttributes.giftsGivenOverThreshold
    ),
    giftsGivenOver3kThreshold: strToBool(
      values.quoteAttributes.giftsGivenOver3kThreshold
    ),
    giftsYearOne: strToBool(values.quoteAttributes.giftsYearOne),
    giftsYearTwo: strToBool(values.quoteAttributes.giftsYearTwo),
    giftsYearThree: strToBool(values.quoteAttributes.giftsYearThree),
    giftsYearFour: strToBool(values.quoteAttributes.giftsYearFour),
    giftsYearFive: strToBool(values.quoteAttributes.giftsYearFive),
    giftsYearSix: strToBool(values.quoteAttributes.giftsYearSix),
    giftsYearSeven: strToBool(values.quoteAttributes.giftsYearSeven),
    giftsYearEight: strToBool(values.quoteAttributes.giftsYearEight),
    giftsYearOneValue: ensureNumberOrNull(
      values.quoteAttributes.giftsYearOneValue
    ),
    giftsYearTwoValue: ensureNumberOrNull(
      values.quoteAttributes.giftsYearTwoValue
    ),
    giftsYearThreeValue: ensureNumberOrNull(
      values.quoteAttributes.giftsYearThreeValue
    ),
    giftsYearFourValue: ensureNumberOrNull(
      values.quoteAttributes.giftsYearFourValue
    ),
    giftsYearFiveValue: ensureNumberOrNull(
      values.quoteAttributes.giftsYearFiveValue
    ),
    giftsYearSixValue: ensureNumberOrNull(
      values.quoteAttributes.giftsYearSixValue
    ),
    giftsYearSevenValue: ensureNumberOrNull(
      values.quoteAttributes.giftsYearSevenValue
    ),
    giftsYearEightValue: ensureNumberOrNull(
      values.quoteAttributes.giftsYearEightValue
    ),
    madeGiftsWithReservationOfBenefit: strToBool(
      values.quoteAttributes.madeGiftsWithReservationOfBenefit
    ),
    hadBusinessOrAgriculturalAssets: strToBool(
      values.quoteAttributes.hadBusinessOrAgriculturalAssets
    ),
    totalBeneficiaries: ensureNumberOrNull(
      values.quoteAttributes.totalBeneficiaries
    ),
    willHasTrusts: strToBool(values.quoteAttributes.willHasTrusts),
    benefittedFromTrust: strToBool(values.quoteAttributes.benefittedFromTrust),
    interestedInEstateAdmin: strToBool(
      values.quoteAttributes.interestedInEstateAdmin
    ),
    factFindCallAt: formatDateTimeToUTC(values.quoteAttributes.factFindCallAt),
    deceasedTitle: values.quoteAttributes.deceasedTitle || null,
    deceasedFirstName: values.quoteAttributes.deceasedFirstName || null,
    deceasedLastName: values.quoteAttributes.deceasedLastName || null,
    hasMadeFuneralArrangements: strToBool(
      values.quoteAttributes.hasMadeFuneralArrangements
    ),
    numberOfProperties: ensureNumberOrNull(
      values.quoteAttributes.numberOfProperties
    ),
    propertyPassingToDirectDescendant:
      strToBool(values.quoteAttributes.propertyPassingToDirectDescendant) ||
      null,
    propertyPassingToDirectDescendantValue: ensureNumberOrNull(
      values.quoteAttributes.propertyPassingToDirectDescendantValue
    ),
    factFindCallPhoneNumber:
      values.quoteAttributes.factFindCallPhoneNumber || null,
    willDraftedByProfessional: strToBool(
      values.quoteAttributes.willDraftedByProfessional
    ),
    willHasIssues: strToBool(values.quoteAttributes.willHasIssues),
    willContested: strToBool(values.quoteAttributes.willContested),
    customerIsNotExecutorNotes:
      values.quoteAttributes.customerIsNotExecutorNotes || null,
    willLeftAllToPartnerOrCharity: strToBool(
      values.quoteAttributes.willLeftAllToPartnerOrCharity
    ),
    hasBeneficiariesUnder18: strToBool(
      values.quoteAttributes.hasBeneficiariesUnder18
    ),
    willAmountPassingAbove325k: strToBool(
      values.quoteAttributes.willAmountPassingAbove325k
    ),
    willNumberOfExecutors: ensureNumberOrNull(
      values.quoteAttributes.willNumberOfExecutors
    ),
    intestacyNumberOfAdministrators: ensureNumberOrNull(
      values.quoteAttributes.intestacyNumberOfAdministrators
    ),
    intestacyPassingToRemoteRelative: strToBool(
      values.quoteAttributes.intestacyPassingToRemoteRelative
    ),
    intestacyPassingToCloseRelative: strToBool(
      values.quoteAttributes.intestacyPassingToCloseRelative
    ),
    intestacyContested: strToBool(values.quoteAttributes.intestacyContested),
    intestacyAmountPassingToChildrenAbove325k: strToBool(
      values.quoteAttributes.intestacyAmountPassingToChildrenAbove325k
    ),
    funeralCostKnown: strToBool(values.quoteAttributes.funeralCostKnown),
    funeralBillAmount: ensureNumberOrNull(
      values.quoteAttributes.funeralBillAmount
    ),
    funeralWakeAmount: ensureNumberOrNull(
      values.quoteAttributes.funeralWakeAmount
    ),
    funeralFlowersAmount: ensureNumberOrNull(
      values.quoteAttributes.funeralFlowersAmount
    ),
    funeralOtherAmount: ensureNumberOrNull(
      values.quoteAttributes.funeralOtherAmount
    ),
    funeralAmountNotes: values.quoteAttributes.funeralAmountNotes || null,
    funeralHadPrePaidPlan: values.quoteAttributes.funeralHadPrePaidPlan || null,
    funeralHasBeenPaid: values.quoteAttributes.funeralHasBeenPaid || null,
    funeralAmountCoveredByPlan: ensureNumberOrNull(
      values.quoteAttributes.funeralAmountCoveredByPlan
    ),
    propertyOwnershipNeedsProbate: strToBool(
      values.quoteAttributes.propertyOwnershipNeedsProbate
    ),
    ihtType: values.quoteAttributes.ihtType || null,
  },
})

// will methods
/** This method returns the market price of the will case. It ignores any discount
 * the customer may have received through a Farewill partner.
 */
const getWillCaseCalculatedPrice = (values: WillLeadAttributes) => {
  return values.quoteAttributes.willPrice
}

const getWillCaseData = (values: WillLeadAttributes) => ({
  ...getCommonData(values),
  calculatedPrice: getWillCaseCalculatedPrice(values),
  quoteAttributes: {
    ...values.quoteAttributes,
    // We are not using 'online' accountTypes anymore, so this overwrites the type
    accountType: 'telephone',
    discountPercentage: values.quoteAttributes.discountPercentage || null,
    everythingInUK: strToBool(values.quoteAttributes.everythingInUK),
    factFindCallAt: formatDateTimeToUTC(values.quoteAttributes.factFindCallAt),
    hasBeneficiaryInvolvedInDivorceOrBankruptcy: strToBool(
      values.quoteAttributes.hasBeneficiaryInvolvedInDivorceOrBankruptcy
    ),
    hasDisabledOrMeansTestedBeneficiary:
      values.quoteAttributes.hasDisabledOrMeansTestedBeneficiary || null,
    hasHighValueAssets: strToBool(values.quoteAttributes.hasHighValueAssets),
    isPaymentTaken: values.quoteAttributes.price === 0,
    lastingPowerOfAttorneyType:
      values.quoteAttributes.lastingPowerOfAttorneyType || null,
    numberOfComplexWills: formatEmptyStringToUndefined(
      values.quoteAttributes.numberOfComplexWills
    ),
    numberOfComplexWillsOverwrite: formatEmptyStringToUndefined(
      values.quoteAttributes.numberOfComplexWillsOverwrite
    ),
    ownsFarm: strToBool(values.quoteAttributes.ownsFarm),
    partner: {
      firstName: values.quoteAttributes.partner?.firstName || null,
      lastName: values.quoteAttributes.partner?.lastName || null,
      dateOfBirth: values.quoteAttributes.partner?.dateOfBirth || null,
      email: values.quoteAttributes.partner?.email || null,
      phoneNumber: values.quoteAttributes.partner?.phoneNumber || null,
      addressFields: {
        lineOne:
          values.quoteAttributes?.partner?.addressFields?.lineOne || null,
        lineTwo:
          values.quoteAttributes?.partner?.addressFields?.lineTwo || null,
        city: values.quoteAttributes?.partner?.addressFields?.city || null,
        postalCode:
          values.quoteAttributes?.partner?.addressFields?.postalCode || null,
        countryCode:
          values.quoteAttributes?.partner?.addressFields?.countryCode || null,
      },
    },
    paymentReference: values.quoteAttributes.paymentReference || null,
    permanentResidence: values.quoteAttributes.permanentResidence || null,
    price: values.quoteAttributes.price
      ? Dinero({
          amount: Math.round(values.quoteAttributes.price * 100),
        }).getAmount()
      : null,
    relationshipStatus: values.quoteAttributes.relationshipStatus || null,
    residence: values.quoteAttributes.residence || null,
    residenceIsPermanentAddress: strToBool(
      values.quoteAttributes.residenceIsPermanentAddress
    ),
    stepRelations: strToBool(values.quoteAttributes.stepRelations),
    wantsLargePrint: strToBool(values.quoteAttributes.wantsLargePrint),
    wantsToGiftBusiness: values.quoteAttributes.wantsToGiftBusiness || null,
    wantsToGiftProperty: values.quoteAttributes.wantsToGiftProperty || null,
    wantsToPutHouseInTrust: strToBool(
      values.quoteAttributes.wantsToPutHouseInTrust
    ),
    willComplexity: values.quoteAttributes.willComplexity,
    willType: values.quoteAttributes.willType || 'single',
  },
})

export const productDataFunctions = {
  probate: getProbateData,
  funeral: getFuneralData,
  cremation: getFuneralData,
  will: getWillCaseData,
  lpa: getLpaData,
  funeral_plan: getFuneralPlanData,
}

/**
 * For the legacy options in RELATIONSHIP_TO_CUSTOMER, we want them to be visible
 * for people who had them previously selected. For any other person, they should
 * not be available options anymore.
 */
export const showRelationshipToCustomerWithLegacy = (
  currentValue?: CustomerRelationship
) => {
  if (!currentValue) {
    return RELATIONSHIP_TO_CUSTOMER.filter((value) => !value.legacy)
  }

  const legacyValues = RELATIONSHIP_TO_CUSTOMER.filter((value) => value.legacy)
  const isCurrentValueLegacy = legacyValues.some(
    (value) => value.value === currentValue
  )

  if (isCurrentValueLegacy) {
    return RELATIONSHIP_TO_CUSTOMER.filter(
      (value) => !value.legacy || (value.legacy && value.value === currentValue)
    )
  }

  return RELATIONSHIP_TO_CUSTOMER.filter((value) => !value.legacy)
}
