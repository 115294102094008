import { FormEvent, useEffect, useState } from 'react'
import { Grid } from '@farewill/ui'
import { useFormikContext } from 'formik'

import { SelectInput } from 'components/form'
import Input from 'components/form/input'
import { Selected } from 'components/form/select-input/types'
import MessageBox from 'components/message-box'
import { StringValueFormOption, formatVoucherCodeOptions } from 'utils/helpers'
import { FAREWILL_PRODUCTS, FarewillProduct } from 'utils/enums'
import { LeadAttributes } from 'lib/models/lead'

import { useLeadContext } from '../context'
import { productsWithoutPartners } from './constants'

export const VoucherType = {
  percentage: 'percentage',
  valueInPence: 'value_in_pence',
} as const

export type LeadVoucher = {
  discountType: (typeof VoucherType)[keyof typeof VoucherType]
  discountValue: number
  code: string
  mechanism?: 'membership'
}

const getDefaultVoucherTypeValue = ({
  attributes,
  availableVouchers,
  isCharity,
}: {
  attributes: LeadAttributes
  availableVouchers: StringValueFormOption[]
  isCharity: boolean
}) => {
  /** If discount percentage applied, default to custom discount */
  if (
    attributes.product === FAREWILL_PRODUCTS.WILL &&
    (attributes.quoteAttributes.discountPercentage || isCharity)
  ) {
    return 'custom_discount'
  }

  /** If voucherCode is same as one of predefined vouchers, default to predefined voucher */
  if (
    attributes.voucherCode &&
    availableVouchers.find(
      (voucher) => voucher.value === attributes.voucherCode
    )
  ) {
    return attributes.voucherCode
  }

  if (attributes.voucherCode || isCharity) {
    return 'other_voucher'
  }

  return ''
}

type DiscountsType = {
  product: FarewillProduct
}

const Discounts = ({ product }: DiscountsType) => {
  const {
    lead: { attributes },
  } = useLeadContext()
  const { handleSubmit, values, setFieldValue } = useFormikContext<
    LeadAttributes & {
      voucherType: string
    }
  >()
  const isCharity =
    values.partnerType === 'charity' &&
    !productsWithoutPartners.includes(product)
  const isTelephoneWillsOtherPartner =
    product === FAREWILL_PRODUCTS.WILL && values.partnerType === 'other_partner'

  // Only hide discount on Telephone Wills, when the partner is charity (wills are then 100% discounted)
  const hideDiscountForTelephoneWill =
    product === FAREWILL_PRODUCTS.WILL && values.partnerType === 'charity'
  // Only hide discount on Probate, when there is a partner
  const hideDiscountForProbate =
    product === FAREWILL_PRODUCTS.PROBATE && values.partnerType !== 'none'
  const hideDiscounts = hideDiscountForTelephoneWill || hideDiscountForProbate

  const setVoucherTypeForm = (value: { name: string; value: Selected }) => {
    handleSubmit(value as unknown as FormEvent<HTMLFormElement>)
  }

  const vouchers = attributes.availableVouchers || []
  /** Vouchers coming from lead (predefined vouchers available for given lead) */
  const availableVouchers = formatVoucherCodeOptions({
    voucherCodes: vouchers,
  })
  const isMembershipVoucherSelected =
    vouchers.find((voucher) => voucher.mechanism === 'membership')?.code ===
    values.voucherCode

  const telephoneWillsOnlyOptions =
    product === FAREWILL_PRODUCTS.WILL
      ? [{ label: 'Custom discount (%)', value: 'custom_discount' }]
      : []

  /** Options available for a select field with Voucher Type */
  const voucherTypeSelectOptions = [
    { label: 'No voucher', value: '' },
    ...availableVouchers,
    ...telephoneWillsOnlyOptions,
    // For Telephone Wills `other partner` we are only displaying custom discount (%)
    ...(isTelephoneWillsOtherPartner
      ? []
      : [{ label: 'Other voucher', value: 'other_voucher' }]),
  ]

  /** Get the default value for voucherType based on the voucherCode and quoteAttributes.discountPercentage */
  const defaultVoucherTypeValue = getDefaultVoucherTypeValue({
    attributes,
    availableVouchers,
    isCharity,
  })

  /** Keep initial state of default voucher type */
  const [voucherType] = useState<string>(defaultVoucherTypeValue)

  useEffect(() => {
    setFieldValue('voucherType', voucherType)
  }, [voucherType, setFieldValue])

  useEffect(() => {
    if (
      availableVouchers.find((voucher) => voucher.value === values.voucherType)
    ) {
      setFieldValue('voucherCode', values.voucherType)
    } else if (values.voucherType === '') {
      setFieldValue('voucherCode', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, values.voucherType, voucherType])

  return hideDiscounts ? null : (
    <Grid.Item>
      <Grid>
        {!isCharity && (
          <Grid.Item spanFromL={6}>
            <SelectInput
              label="Voucher type"
              name="voucherType"
              handleSave={setVoucherTypeForm}
              options={voucherTypeSelectOptions}
            />
          </Grid.Item>
        )}
        {values.voucherType === 'other_voucher' && (
          <Grid.Item spanFromL={6}>
            <Input
              label="Voucher code"
              name="voucherCode"
              handleSave={() => handleSubmit()}
              allowCopyToClipboard
            />
          </Grid.Item>
        )}
        {values.voucherType === 'custom_discount' && !isCharity && (
          <Grid.Item spanFromL={6}>
            <Input
              label="Discount value"
              name="quoteAttributes.discountPercentage"
              handleSave={() => handleSubmit()}
              suffix="%"
              allowCopyToClipboard
            />
          </Grid.Item>
        )}

        {isCharity && (
          <Grid.Item>
            <MessageBox warning hideIcon margin={['S', 0, 'M']}>
              Please confirm the voucher code value with the customer. If a
              value has auto-filled based on tracking data and it does not look
              like a voucher code please clear the field.
            </MessageBox>
          </Grid.Item>
        )}

        {isMembershipVoucherSelected && (
          <Grid.Item>
            <MessageBox warning hideIcon margin={['S', 0, 'M']}>
              This person is is eligible for member discounts. To apply their
              discount in Square use the generic discount code: Membership
              discount.
            </MessageBox>
          </Grid.Item>
        )}
      </Grid>
    </Grid.Item>
  )
}

export default Discounts
